import React from "react";
import contctbnr from "../img/contctbnr.png";
import complain from "../img/complain.png";
import add from "../img/add.png";
import email from "../img/email.png";
import telephone from "../img/telephone.png";
import { Link } from "react-router-dom";
import gentlman0 from "../img/gentlman0.jpg";
import Layout from "../layouts/Layout";
import bootomarrow from "../img/bootomarrow.png";

const ContactPage = () => {
  return (
    <Layout header={1} footer={3} className="" mainClassName="">
    <section className="contactbnr">
      <div className="container">
        <div className="row">
          <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
          <div className="conatctcontent">
          <h1>Contact Us</h1>
          <p>Want a competitive edge for your business? We’re to assist you with a wide array of offshoring solutions. Let’s discuss the possibilities and work together to grow your business. </p>
          <div className="Assurance">
           <Link to="tel:+91 7837746949" className="btn " >Connect with us </Link>
                </div>
                </div>
         </div>
         <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
         <img src={contctbnr} className="contctbnr" alt="" />
         </div>
        </div>
      </div>
    </section>
    <section className="in-sec-1">
  <div className="container">
    <div className="row">
      <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
        <div className="brow">
          <h2 className="encompass">We’d love to hear from you </h2>
          <h3>Get in touch today! </h3>
          <p>Our team is just a call away to provide you with personalized solutions and exceptional support. Reach out to us today to explore how we can benefit your business. </p>
     
        </div>
      </div>
    </div>
  </div>
  <img src={bootomarrow} className="bootomarrow" alt="" />
</section>
   <section className="contactdetail" >
    <div className="container">
      <div className="row">
      <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
        <img src={gentlman0} className="gentlman0" alt="" />
        </div>
        <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
     
          <div className="cont-form" id="contv">
          <form action="Service.php" method="post" enctype="multipart/form-data">
          <ul>
          <li><input type="text" name="name" className="form-control"  placeholder="Name" required /></li>
           <li><input type="email" name="email" className="form-control" placeholder="Email"  required /></li>
           <li><input type="tel" name="phone" className="form-control" placeholder="Phone" required /></li>
            <li> <textarea id="message" title="Message" cols="30"  name='message'  rows="1"  maxlength="500"  placeholder="How can we help you?" required /></li>
             <li><input type="submit" className="form-rol" defaultValue="send" /></li>
          </ul>
           </form>
          </div>
        </div>
      
      </div>
      <div className="row" id="fddf">
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
            <div className="cont-box">
            <img src={complain} className="complain" alt="" />
              <h4>Call Us</h4>
              <p><a className="Calling" href="tel:+91 7837746949">+91 7837746949</a></p>
              <p><a className="Calling" href="tel:+91 9915446949">+91 9915446949</a></p>
            </div>
          </div>
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
            <div className="cont-box">
            <img src={email} className="complain" alt="" />
              <h4>Email Us</h4>
              <p><a className="Calling" href="malito:info@logixm.com">info@logixm.com</a></p>
            </div>
          </div>
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
            <div className="cont-box">
            <img src={add} className="complain" alt="" />
              <h4>Location</h4>
              <p>IT-C-7, Netsmartz Center, 6th Floor, Sector 67 SAS Nagar, Punjab-160062</p>
            </div>
          </div>
        </div>
    </div>
   </section>  

    </Layout>
  );
};

export default ContactPage;
