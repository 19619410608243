import React from "react";
import Layout from "../layouts/Layout";
import bootomarrow from "../img/bootomarrow.png";

const Privacypolicy = () => {
  return (
    
    <Layout header={1} footer={3} className="" mainClassName="">
 
    <section className="Privacy-pilicy">
  <div className="container">
    <div className="row">
      <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
        <div className="privacy"> 
          <h2 className="encompass">Privacy-Policy</h2>
               </div>
      </div>
    </div>
  </div>
  <img src={bootomarrow} className="bootomarrow" alt="" />
</section>
<section className="policies">
    <div className="container">
        <div className="row">
            <div className="col-xxl-12 col-md-12 col-12">
                <h2>Privacy Policy</h2>
                <div className="policy-box">
                    <p>Your data privacy is of utmost importance to us. This privacy policy outlines what personal information we collect and how we use your information. You’re advised to review this privacy policy carefully to stay updated with our privacy practices. By accessing this Website and/or submitting any information to us, you agree to this policy. If you don't agree with our privacy practices, please stop using our website. </p>
                    <h4>Information we collect </h4>
                    <p>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information. If you reach out to us directly, we may collect information such as your name, email address, phone number, the content of your message, and any attachments you send us. </p>
                    <h4>How we use your information </h4>
                    <p>We utilize the information we collect for several purposes, including:</p>
                    <ul className="collect">
                        <li>Providing, operating, and maintaining our website</li>
                        <li>Enhancing, personalizing, and extending the functionality of our website</li>
                        <li>Analyzing and understanding how you use our website</li>
                        <li>Developing new products, services, features, and functionalities</li>
                        <li>Communicating with you directly for customer service, updates, and other website-related information, as well as for marketing and promotional activities</li>
                        <li>Sending emails</li>
                        <li>Identifying and preventing fraudulent activities</li>
                    </ul>
                    <h4>How we share your personal information </h4>
                    <p>We’re committed to safeguarding your privacy. We will never sell, share, lease or distribute your personal information to any third party without your prior permission. </p>
                    <h4>How we secure your personal information </h4>
                    <p>We have put in place robust security measures to safeguard your personal information from unauthorized use or access. Unfortunately, no data transmission over the Internet can be guaranteed to be 100% secure. Despite our best efforts, we cannot ensure or warrant the security of any information transmitted via the Internet. Once we receive your transmission, we will make reasonable efforts to ensure its security on our systems.</p>
                    <h4>Cookies </h4>
                    <p>Like any other website, we also use cookies. These cookies are used to store information such as visitors’ preferences and the pages on the website that the visitor accessed or visited. The information helps us optimize the users’ experience by customizing our web page content based on visitors’ browser type and other information.You have the option to manage or disable cookies through your browser settings. Disabling cookies may affect the functionality of our website and you may not be able to enjoy the full features of our website. </p>
                     <h4>Data protection rights </h4>
                     <p>You have certain rights with regard to your personal information. If you wish to exercise any of these rights, please contact us. </p>
                     <ul className="collect">
                        <li>Right to request access to your personal data and information about how it is being processed.</li>
                        <li>Right to request the correction of inaccurate or incomplete personal data.</li>
                        <li>Right to request the deletion of personal data under certain circumstances.</li>
                        <li>Right to request the restriction of processing of personal data under certain circumstances.</li>
                        <li>Right to receive a copy of your personal data. </li>
                        <li>Right to object to the processing of personal data under certain circumstances</li>
                     </ul>
                     <h4>Changes to the privacy policy </h4>
                     <p>We may modify our privacy policy from time to time. You’re advised to visit this page regularly to stay updated with our modified privacy practices. </p>
                     <h4>Contact us </h4>
                     <p>If you have any questions or queries about our privacy practices, please feel free to reach out to us at <a href="mailto:info@logixm.com">info@logixm.com</a></p>

                </div>
            </div>
        </div>
    </div>
</section>


    </Layout>
  );
};

export default Privacypolicy;
