import React from "react";
import Layout from "../layouts/Layout";
import bootomarrow from "../img/bootomarrow.png";

const Termsconditions = () => {
  return (
    
    <Layout header={1} footer={3} className="" mainClassName="">
 
    <section className="Privacy-pilicy">
  <div className="container">
    <div className="row">
      <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
        <div className="privacy"> 
          <h2 className="encompass">Terms & Conditions</h2>
               </div>
      </div>
    </div>
  </div>
  <img src={bootomarrow} className="bootomarrow" alt="" />
</section>
<section className="policies">
    <div className="container">
        <div className="row">
            <div className="col-xxl-12 col-md-12 col-12">
                <h2>Terms of use </h2>
                <div className="policy-box">
                   <p>These terms of use govern the use of the website located at<a href="mailto:info@logixm.com"> info@logixm.com</a>. By accessing this website, you agree to accept these terms and conditions. If you do not agree to the terms and conditions stated on this page, please stop using our website. </p>
                   <p>The following terminology applies to these Terms of Use, Privacy Statement and all Agreements: “Client”, “You” and “Your” refer to the users or clients interacting with our website. “The Company”, “Ourselves”, “We”, “Our” and “Us”, refers to our Company. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to the same.</p>
                   <h4>General </h4>
                   <p>This website is protected by copyright laws, and adherence to the copyright terms and conditions is mandatory. The information and content on this website are not to be republished, reproduced, distributed, or used for any plagiaristic work without our prior written consent. Unless expressly permitted by us, you may only view the pages on this website on your computer for personal and non-commercial use. None of the content on this website may be mirrored on another website or any other media. Additionally, you are not permitted to modify any of the content, and you must comply with all copyright, proprietary notices, conditions, and trademarks mentioned on this website.</p>
                   <h4>Disclaimer</h4>
                 <p>This website may contain clerical errors, typographical errors, and inaccuracies. We expressly disclaim any obligation to update this website or any information contained herein. We reserve the right, in our sole discretion, to correct errors or omissions on this website and to make any other changes to the website and its content at any time without prior notice. We do not guarantee the accuracy, reliability, or completeness of any statement, opinion, or other information displayed or distributed through this website. Your reliance on any information provided on the website is at your own risk. </p>
                 <h4>Limitation of liability </h4>
                 <p>We shall not be liable to any party for any special, exemplary, incidental, direct, indirect, or consequential damages of any kind arising from or related to this website, including any use of this website, any website or resource referenced, linked, or accessed from this website, and downloading and accessing information, materials, products, and services. This includes, but is not limited to, business disruptions, lost savings, profits, programs, or any other type of data. This limitation of liability applies to all actions and causes, whether based on contract, tort, warranty, or any other legal theories.</p>
                 <h4>Indemnity </h4>
                 <p>You agree to hold us, our subsidiaries and our affiliates harmless and indemnified from any judgement, claim, expense, cost or any other loss arising from your use of this website. This also includes without limitation any action taken by you that violates the terms listed in our terms of use. </p>
                 <h4>No warranties </h4>
                 <p>This website and all the information provided herein are offered without any representation or warranty, whether expressed or implied. This includes but is not limited to, warranties of non-infringement, merchantability, or fitness for any particular purpose. There is no expressed or implied warranty regarding third-party content, nor is there a warranty that this website will be free from computer viruses. </p>
                 <h4>Links external websites </h4>
                 <p>This website may contain links to external third-party websites. We are not liable for the privacy practices of such third-party websites nor do we endorse them. We don't guarantee the accuracy, reliability and completeness of third-party website content. You’re advised to read the privacy policies of these websites before providing them with your personal information. </p>
                 <h4>Governing law</h4>
                 <p>These terms of use are governed by and construed in accordance with the laws of India.</p>
                 <h4>Changes to the terms </h4>
                 <p>We may modify these terms of use from time to time. You’re advised to visit this page frequently to stay updated with the changes in our terms of use. </p>
                 <h4>Contact us</h4>
                 <p>If you have any questions or queries regarding these terms of use, please feel free to contact us at <a href="mailto:info@logixm.com">info@logixm.com</a></p>

                </div>
                </div>
            </div>
        </div>
  
</section>


    </Layout>
  );
};

export default Termsconditions;
