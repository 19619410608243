import React from "react";
import { Link } from "react-router-dom";
import leadss from "../img/leadss.png";
import inner002 from "../img/inner002.png";
import logi006 from "../img/logi006.png";

import targetadu from "../img/targetadu.png";
import acquisition from "../img/acquisition.png";
import LeadNurturing from "../img/LeadNurturing.png";
import gear from "../img/gear.png";
import LeadScoring from "../img/LeadScoring.png";
import DataManagement from "../img/DataManagement.png";
import client from "../img/client.png";
import mailgh from "../img/mailgh.png";
import Layout from "../layouts/Layout";
import bootomarrow from "../img/bootomarrow.png";
import inner003 from "../img/inner003.png";
import key2 from "../img/key2.png";
import Accordion from 'react-bootstrap/Accordion';
import star from "../img/star.png";
import 'react-tabs/style/react-tabs.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


const LeadGeneration = () => {
  return (
    <Layout header={1} footer={3} className="" mainClassName="">
  <section className="obinner">
    <div className="container">
        <div className="row">
        <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
        <div className="acent">
          <h1 className="dgjhj">Fuel your business <span class="green1">  growth</span> with efficient lead generation services</h1>
          <p className="custmer1"> With our lead generation efforts, you can attract, engage, and convert high-quality leads into valuable customers for your business. </p>
          <ul className="in-ul">
            <li ><span><img src={star} className="star" alt="" /></span> Real conversions  </li>
            <li><span><img src={star} className="star" alt="" /></span>Increased sales   </li>
            <li><span><img src={star} className="star" alt="" /></span>Maximized revenue  </li>
          </ul>

          <Link
                to="/contact"
                className="btn"
                         >
               Get started now <span></span>
              </Link> 
          </div>
        </div>
          <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
            <img src={leadss} className="gh" alt="" />
          </div>
        </div>
      </div>
    </section>
<section className="in-sec-1">
  <div className="container">
    <div className="row">
      <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
        <div className="brow">
          <h2 className="encompass">Tailored solutions to generate convertible leads </h2>
          <h3>We identify, qualify, and nurture leads to maximize conversion rates. </h3>
          <p>At Logixm, we specialize in creating qualified prospect opportunities to fuel your business expansion. Our solutions are designed to analyze your database, identify high-quality leads, segment them effectively, and develop engaging interactions to convert them into loyal customers. </p>
        </div>
      </div>
    </div>
  </div>
  <img src={bootomarrow} className="bootomarrow" alt="" />
</section>
<section className="ib-serivces">
  <div className="container">
    <div className="row">
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
        <div className="led">
        <img src={targetadu} className="inbo" alt="" />
          <h4>Target audience identification  </h4>
          <p className="appropriate">Identifying and defining your ideal target audience by analyzing demographics, firmographics and behavioural traits to ensure precise targeting. </p>
       
        </div>
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
        <div className="led">
        <img src={acquisition} className="inbo" alt="" />
          <h4>Lead evaluation </h4>
          <p className="appropriate">Assessing leads against pre-established criteria to ascertain their eligibility as qualified prospects.  </p>
               </div>
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
        <div className="led">
        <img src={LeadNurturing} className="inbo" alt="" />
          <h4>Lead nurturing </h4>
          <p className="appropriate">
          Engaging with leads through personalized communication and guiding them through the sales funnel. 
          </p>
         </div>
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
        <div className="led">
        <img src={gear} className="inbo" alt="" />
          <h4>Appointment setting  </h4>
          <p className="appropriate">
          Setting appointments with qualified leads who have a genuine interest in your products or services to pursue opportunities for conversions. 

          </p>
         </div>
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
        <div className="led">
        <img src={LeadScoring} className="inbo" alt="" />
          <h4>Lead scoring</h4>
          <p className="appropriate">
          Prioritizing high-value leads by assigning scores on the basis of their engagement levels, interest and alignment with your customer profile. 

          </p>
         </div>
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
        <div className="led">
        <img src={DataManagement} className="inbo" alt="" />
          <h4>Data management </h4>
          <p className="appropriate">
          Ensuring accuracy and updation of lead data by including additional details for precise targeting and personalized communication. 
          </p>
         </div>
      </div>
    </div>
  </div>
</section>
<hr />
<section className="whyib">
  <div className="container">
    <div className="row text-center" id="Prefer">
      <div className="col-xxl-12 col-xl-12 col-md-12 col-12"> 
        <h2>Unique features that help <br /><span className="lightspan">us pioneer excellence </span></h2>
      </div>
    </div>
    <div className="row" id="scalability">
    <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
    <div className="newroel">
      <img src={inner002} className="bann" alt="" />
    </div>
     </div>
    <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
  <div className="outervoice">
    <div className="voice">
        <div className="rightnew">
        <h4>Short turnaround </h4>
        <p>Drawing on our vast experience, we excel in fostering engagement and nurturing leads interest without wasting a huge chunk of time.</p>
      </div>
    
    </div>
   <div className="voice">
     <div className="rightnew">
      <h4>Cutting-edge tools and technology </h4>
      </div>
      <p>Our state-of-the-art tools and technologies allow us to find and track relevant leads with minimal effort. </p>
    
    </div>
    <div className="voice">
     <div className="rightnew">
      <h4> Experienced agents </h4>
      </div>
      <p>Our agents have expertise in the latest lead-generation strategies and techniques that help us close the gap in your sales volume through multichannel engagement. </p>
    
    </div>
   
    </div>
    </div>
    </div>
  </div>
</section>
<section className="techno">
  <div className="container">
  <div className="row text-center" id="c1">
      <div className="col-xxl-12 col-md-12 col-12">
      <h2 className="technology text-white ">Request a  <span class="green1">callback</span></h2>
      <p className="premier text-white">By partnering with us, you’re choosing a strategic partner dedicated to growing your business. Reach out to us to discover how we can help scale your business.</p>
      <div className="webser">
      
      <form action="Service.php" method="post" enctype="multipart/form-data">
          <ul>
          <li><input type="text" name="name" className="form-control"  placeholder="Name" required /></li>
           <li><input type="email" name="email" className="form-control" placeholder="Email"  required /></li>
           <li><input type="tel" name="phone" className="form-control" placeholder="Phone" required /></li>
            <li> <textarea id="message" title="Message" cols="30"  name='message'  rows="1"  maxlength="500"  placeholder="Enter Message" required /></li>
             <li><input type="submit" className="form-rol" defaultValue="send" /></li>
          </ul>
           </form>
                    </div>
      </div>
    </div>
   

  </div>
</section>
<section className="ibfeatures">
  <div className="container">
    <div className="row text-center">
      <div className="col-xxl-12 col-xl-12 col-md-12 col-12">  
       <h2> Going over and beyond with <br /> <span className="lightspan">lead generation services </span></h2>
        <div className="sfs">
        <Tabs>
       <TabList>
      <Tab>Targeted approach </Tab>
      <Tab>Continuous optimization </Tab>
    </TabList>

    <TabPanel>
      <div className="row" id="tures">
     <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12" id="keyto">
      <img src={inner003} className="key1" alt="" />
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12" >
      <div className="Distrution"> 
      <h3> Targeted lead generation <span className="lightspan"> to enhance efficiency</span></h3>
        <div className="leding">
                <h4>Advanced targeting strategies </h4>
          <p className="appropriate"> We leverage sophisticated tools and techniques that go beyond basic demographic segmentation to target leads based on their behaviour, preferences and needs.  </p>
          <p className="appropriate">By implementing lead scoring techniques, we prioritize prospects based on their level of engagement and interest in your products or services.  </p>
          </div>
          <div className="bnrc">
          <Link
                to="/contact"
                className="btn ">
               Contact Us
              </Link> 
           </div>
      </div>
      </div>
      </div>
    </TabPanel>
    <TabPanel>
    <div className="row" id="tures">
      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">
      <div className="Distrution"> 
      <h3>Continuous optimization to <span className="lightspan"> deliver maximum impact</span></h3>
        <div className="leding">
    <h4>Generating better results through improvement </h4>
          <p className="appropriate">We closely monitor and analyze key performance indicators, including call metrics, lead qualification rates, conversion rates and other indicators to identify areas for improvement. </p>
          <p className="appropriate">Furthermore, we consistently train our agents to ensure they are equipped with the latest practices and techniques to deliver enhanced results. </p>
          </div>
          <div className="bnrc">
          <Link
                to="/contact"
                className="btn ">
               Contact Us
              </Link> 
           </div>
      </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12" id="keyto">
      <img src={logi006} className="key1" alt="" />
      </div>
      </div>
    </TabPanel>
  </Tabs>
        </div>
      </div>
    </div>
   
  </div>
</section>
<section className="stsret">
  <div className="container">
    <div className="row text-center">
      <div className="col-xxl-12"> 
        <h2>Frequently asked<span class="lightspan"> questions  </span></h2>
        <p className="Get">Find answers to the most common questions about our lead generation services. If you have a question not answered here, please feel free to <a className="hightlightedtext" href="/contact">contact us.</a></p>

      </div>
      <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>How do you ensure the quality of leads generated through your services?</Accordion.Header>
        <Accordion.Body>
        We use a combination of lead qualification criteria, data validation processes, and agent training to ensure that we generate high-quality and relevant leads for your business. Also, we prioritize leads on the basis of their engagement level, interest in your products or services and potential for conversion.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Can you target specific demographics with your lead generation efforts?</Accordion.Header>
        <Accordion.Body>
        Yes, we can create tailored messaging and scripts that speak directly to the needs and interests of each demographic segment and address their pain points. 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>What kind of reporting and analytics do you provide for lead generation services?</Accordion.Header>
        <Accordion.Body>
        We provide detailed reports on our performance, including call metrics, call volume, duration, lead qualification rates, conversion rates, appointment conversion rates, customer feedback and other key performance indicators.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>How do you handle lead follow-up and nurturing after initial contact?
</Accordion.Header>
        <Accordion.Body>
        We offer lead nurturing services to follow up with qualified leads. We proactively engage with them to provide additional information, address their queries or concerns, and nurture relationships through ongoing communication and engagement. 

        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>How do you handle objections and unfavourable responses during lead-generation calls?</Accordion.Header>
        <Accordion.Body>
        We train our agents to handle objections diligently by carefully listening to the prospects, addressing their concerns and providing them with additional information for better clarity. We strive to turn objections into opportunities by demonstrating the value of your products and services to the prospects. 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header>What distinguishes your lead generation services from others in the industry?
</Accordion.Header>
        <Accordion.Body>
        Our lead generation services stand out for a myriad of reasons. We have an experienced team of agents well-versed in advanced technology and tools for generating relevant and convertible leads for your business. With our personalized approach and commitment to continuous optimization, we strive to nurture leads through increased engagement to improve the chances of conversions. 

        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    </div>
  </div>
</section>
    </Layout>
  );
};

export default LeadGeneration;
