import React from "react";
import { Link } from "react-router-dom";
import oprate from "../img/oprate.png";
import inner7 from "../img/inner7.png";
import inner6 from "../img/inner6.png";

import dashboard from "../img/dashboard.png";
import DigitalSolutions from "../img/DigitalSolutions.png";
import WebDevelopment from "../img/WebDevelopment.png";
import telemarketing from "../img/telemarketing.png";
import TechnicalSupport from "../img/TechnicalSupport.png";
import onboarding from "../img/onboarding.png";
import logi005 from "../img/logi005.png";
import mailgh from "../img/mailgh.png";
import Layout from "../layouts/Layout";
import bootomarrow from "../img/bootomarrow.png";
import key1 from "../img/key1.png";
import key2 from "../img/key2.png";
import Accordion from 'react-bootstrap/Accordion';
import star from "../img/star.png";
import 'react-tabs/style/react-tabs.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


const OperationsManagement = () => {
  return (
    <Layout header={1} footer={3} className="" mainClassName="">
    <section className="obinner">
    <div className="container">
        <div className="row">
        <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
        <div className="acent"> 
          <h1 className="dgjhj"> Streamline your business <span class="green1">  operations</span> and increase efficiency  </h1>
          <p className="custmer1"> Enhance your core business operations while minimizing costs and maximizing efficiency through innovative operations management solutions. </p>
          <ul className="in-ul">
            <li ><span><img src={star} className="star" alt="" /></span>  Quality assurance   </li>
            <li><span><img src={star} className="star" alt="" /></span>Up to 60% cost reduction </li>
            {/* <li><span><img src={star} className="star" alt="" /></span>Improved productivity    </li> */}
          </ul>

          <Link
                to="/contact"
                className="btn "
          
              >
               Get started now
              </Link> 
          </div>
        </div>
          <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
            <img src={oprate} className="gh" alt="" />
          </div>
        </div>
      </div>
    </section>

<section className="in-sec-1">
  <div className="container">
    <div className="row">
      <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
        <div className="brow">
          <h2 className="encompass"> Next-gen solutions to refine your operations  </h2>
          <h3>We help you maximize productivity and cut down costs.  </h3>
          <p>We offer a wide array of solutions to manage your day-to-day operations, helping you save valuable time to focus on your core functions that propel business growth. </p>
        </div>
      </div>
    </div>
  </div>
  <img src={bootomarrow} className="bootomarrow" alt="" />
</section>
<section className="ib-serivces">
  <div className="container">
    <div className="row">
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
        <div className="led">
        <img src={dashboard} className="inbo" alt="" />
          <h4>Research and data analysis </h4>
          <p className="appropriate">Our team conducts in-depth research to collect and analyze data to understand market trends, customer behavior and your competitors.  </p>
       
        </div>
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
        <div className="led">
        <img src={DigitalSolutions} className="inbo" alt="" />
          <h4>Digital solutions  </h4>
          <p className="appropriate">We offer a vast range of digital solutions to transform traditional business processes and operations into digital formats. </p>
       
        </div>
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
        <div className="led">
        <img src={WebDevelopment} className="inbo" alt="" />
          <h4>Web development </h4>
          <p className="appropriate">
          Our team excels in designing and developing responsive, user-friendly websites optimized for performance, security and search engine visibility. 
          </p>
         </div>
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
        <div className="led">
        <img src={telemarketing} className="inbo" alt="" />
          <h4>Customer support services </h4>
          <p className="appropriate">
          Leveraging multiple channels, we provide seamless customer support to ensure a higher level of customer satisfaction. 
          </p>
         </div>
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
        <div className="led">
        <img src={TechnicalSupport} className="inbo" alt="" />
          <h4>Technical support </h4>
          <p className="appropriate">
          We provide technical assistance and troubleshooting for IT-related issues such as software installation, configuration and connectivity problems. 

          </p>
         </div>
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
        <div className="led">
        <img src={onboarding} className="inbo" alt="" />
          <h4>Marketing operations</h4>
          <p className="appropriate">
          We manage marketing campaigns for our clients, including camping planning, execution, tracking and analysis to optimize marketing ROI and boost growth. 

          </p>
         </div>
      </div>
    </div>
  </div>
</section>
<hr />
<section className="whyib">
  <div className="container">
    <div className="row text-center" id="Prefer">
      <div className="col-xxl-12 col-xl-12 col-md-12 col-12">   
        <h2>Reasons that help us <br /><span className="lightspan">surpass expectations  </span></h2>
      </div>
    </div>
    <div className="row" id="scalability">
    <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
    <div className="newroel">
      <img src={inner6} className="bann" alt="" />
    </div>
     </div>
    <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
  <div className="outervoice">
    <div className="voice">
        <div className="rightnew">
        <h4>Cutting-edge infrastructure  </h4>
        <p>
        We have access to state-of-the-art tools and technologies to empower your business to stay competitive. 
</p>
      </div>
    
    </div>
   <div className="voice">
     <div className="rightnew">
      <h4> Quick turnaround   </h4>
      </div>
      <p>
      Our agile approach allows us to deliver high-quality services in minimal time without exceeding deadlines. </p>
    
    </div>
    <div className="voice">
     <div className="rightnew">
      <h4> Scalable services </h4>
      </div>
      <p>We possess the bandwidth to scale up and expand our service capabilities according to your evolving business needs.  </p>
    
    </div>
   
    </div>
    </div>
    </div>
  </div>
</section>
<section className="techno">
  <div className="container">
  <div className="row text-center" id="c1">
      <div className="col-xxl-12 col-md-12 col-12">
      <h2 className="technology text-white ">Request a  <span class="green1">callback</span></h2>
      <p className="premier text-white">By partnering with us, you’re choosing a strategic partner dedicated to growing your business. Reach out to us to discover how we can help scale your business.</p>
      <div className="webser">
      
      <form action="Service.php" method="post" enctype="multipart/form-data">
          <ul>
          <li><input type="text" name="name" className="form-control"  placeholder="Name" required /></li>
           <li><input type="email" name="email" className="form-control" placeholder="Email"  required /></li>
           <li><input type="tel" name="phone" className="form-control" placeholder="Phone" required /></li>
            <li> <textarea id="message" title="Message" cols="30"  name='message'  rows="1"  maxlength="500"  placeholder="Enter Message" required /></li>
             <li><input type="submit" className="form-rol" defaultValue="send" /></li>
          </ul>
           </form>
                    </div>
      </div>
    </div>
   

  </div>
</section>
<section className="ibfeatures">
  <div className="container">
    <div className="row text-center">
      <div className="col-xxl-12 col-xl-12 col-md-12 col-12"> 
       <h2>What differentiates <span className="lightspan">us   </span></h2>
        <div className="sfs">
        <Tabs>
       <TabList>
      <Tab>Customized solutions  </Tab>
      <Tab>Dedicated team</Tab>
    </TabList>

    <TabPanel>
      <div className="row" id="tures">
      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12" id="keyto">
      <img src={inner7} className="key1" alt="" />
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12" >
      <div className="Distrution">  
      <h3>Personalized solutions  <span className="lightspan"> for your success </span></h3>
        <div className="leding">
              <h4>Customized solutions    </h4>
          <p className="appropriate">Our solutions are tailored precisely to suit your business’s unique requirements and objectives. </p>
          <p className="appropriate">We invest the effort to understand your goals and devise strategies that lead your business towards success.  </p>
          </div>
          <div className="bnrc">
          <Link
                to="/contact"
                className="btn ">
               Contact Us
              </Link> 
           </div>
      </div>
      </div>
      </div>
    </TabPanel>
    <TabPanel>
    <div className="row" id="tures">
      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">
      <div className="Distrution">
      <h3>Experienced team dedicated to delivering exceptional results  </h3>
        <div className="leding">
          <h4>Experienced and reliable team </h4>
          <p className="appropriate"> Backed by years of experience, our seasoned team provides top-notch services and remarkable results.  </p>
          <p className="appropriate">With diverse expertise and the ability to leverage the latest tools and technologies for your success, our dedicated team is committed to achieving excellence. </p>
          </div>
          <div className="bnrc">
          <Link
                to="/contact"
                className="btn ">
               Contact Us
              </Link> 
           </div>
      </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12" id="keyto">
      <img src={logi005} className="key1" alt="" />
      </div>
      </div>
    </TabPanel>
  </Tabs>
        </div>
      </div>
    </div>
   
  </div>
</section>
<section className="stsret">
  <div className="container">
    <div className="row text-center">
      <div className="col-xxl-12"> 
        <h2>Frequently asked<span class="lightspan"> questions  </span></h2>
        <p className="Get">Find answers to the most common questions about our business operations management services. If you have a question not answered here, please feel free to  <a className="hightlightedtext" href="/contact">contact us.</a></p>

      </div>
      <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>What makes Logixm an expert in managing business operations?</Accordion.Header>
        <Accordion.Body>
        At Logixm, we have years of experience and a dedicated team of professionals who specialize in managing various business operations. Our expertise spans multiple industries, and we have a proven track record of delivering exceptional results for our clients.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Can you accommodate businesses of all sizes and industries?
</Accordion.Header>
        <Accordion.Body>
        Yes, we have the expertise to accommodate businesses of all sizes and industries, from startups to large enterprises. We tailor our scalable solutions to meet the needs of businesses across various sectors and ensure that we can accommodate your specific requirements.

        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>How do you ensure accuracy and efficiency in business operations?  </Accordion.Header>
        <Accordion.Body>
        At Logixm, our experienced team is well-versed in managing business operations with precision and expertise. We adopt streamlined workflows, rigorous quality control measures, and cutting-edge technologies to ensure unparalleled accuracy and efficiency in every aspect of your operations. Additionally, our commitment to regular audits and compliance checks enables us to uphold the highest standards of quality and reliability.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>Can you customize your services according to my unique requirements?</Accordion.Header>
        <Accordion.Body>
        Absolutely. We understand that each business has unique requirements. We offer flexible and customizable services tailored to your unique business needs. We work closely with you to understand your operations and provide a solution that resonates with your objectives and processes. 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>What sets your business operations management services apart from others? </Accordion.Header>
        <Accordion.Body>
        Our dedication to excellence, customer-centric approach and commitment to consistent improvement provide us a competitive edge over others. We go above and beyond to deliver top-notch results to our clients, making us the go-to destination for operations management solutions. 

        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header>Is my confidential data safe and secure with Logixm?
</Accordion.Header>
        <Accordion.Body>
        Yes. We adhere to strict data protection standards and protocols to protect your sensitive information. Our experienced team is trained to handle your sensitive information effectively. Moreover, we comply with industry best practices to ensure the utmost protection of your confidential data. 
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    </div>
  </div>
</section>
    </Layout>
  );
};

export default OperationsManagement;
