import React from "react";
import { Link } from "react-router-dom";
import obbnr from "../img/obbnr.png";
import outboundpick2 from "../img/outboundpick2.png";
import ibpick1 from "../img/ibpick1.png";
import userlead from "../img/userlead.png";

import telemarketing from "../img/telemarketing.png";
import crossselling from "../img/crossselling.png";
import acquisition from "../img/acquisition.png";
import managers from "../img/managers.png";
import bankruptcy from "../img/bankruptcy.png";
import client from "../img/client.png";
import mailgh from "../img/mailgh.png";
import Layout from "../layouts/Layout";
import bootomarrow from "../img/bootomarrow.png";
import ibservices from "../img/ibservices.png";
import support24 from "../img/support24.png";
import Accordion from 'react-bootstrap/Accordion';
import star from "../img/star.png";
import 'react-tabs/style/react-tabs.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


const OutboundSupport = () => {
  return (
    <Layout header={1} footer={3} className="" mainClassName="">
    <section className="obinner">
    <div className="container">
        <div className="row">
        <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
        <div className="acent">
          <h1 className="dgjhj">Grow your <span class="green1"> sales and ROI</span> with efficient outbound support</h1>
          <p className="custmer1"> We streamline your business operations, optimize customer engagement and boost your sales through strategic outreach. </p>
          <ul className="in-ul">
            <li ><span><img src={star} className="star" alt="" /></span> Effective lead management  </li>
            <li><span><img src={star} className="star" alt="" /></span>Increased conversions  </li>
            {/* <li><span><img src={star} className="star" alt="" /></span>Substantial business growth </li> */}
          </ul>

          <Link
                to="/contact"
                className="btn"
                        >
              Get started now 
              </Link> 
          </div>
        </div>
          <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
            <img src={obbnr} className="gh" alt="" />
          </div>
        </div>
      </div>
    </section>

<section className="in-sec-1">
  <div className="container">
    <div className="row">
      <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
        <div className="brow">
          <h2 className="encompass">Comprehensive outbound solutions <br />for business growth  </h2>
          <h3>We convert every call into a sales opportunity. </h3>
          <p>From initiating cold calling to conducting market research and winning back lost sales, our dedicated team excels in every aspect of outbound calling. </p>
        </div>
      </div>
    </div>
  </div>
  <img src={bootomarrow} className="bootomarrow" alt="" />
</section>
<section className="ib-serivces">
  <div className="container">
    <div className="row">
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
        <div className="led">
        <img src={userlead} className="inbo" alt="" />
          <h4>Lead generation</h4>
          <p className="appropriate">We use AI-driven analytics to understand customer behavior and devise customized strategies for increased engagement with potential customers. </p>
         </div>
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
        <div className="led">
        <img src={telemarketing} className="inbo" alt="" />
          <h4>Telemarketing</h4>
          <p className="appropriate">We leverage cutting-edge CRM systems to analyze customer interactions and create highly effective campaign approaches to drive success. </p>
       
        </div>
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
        <div className="led">
        <img src={crossselling} className="inbo" alt="" />
          <h4>Cross-selling & upselling</h4>
          <p className="appropriate">
          By harnessing predictive analytics and a data-driven approach, we strive to increase per-customer sales, enhance customer satisfaction and improve ROI. 
          </p>
         </div>
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
        <div className="led">
        <img src={acquisition} className="inbo" alt="" />
          <h4>Customer acquisition</h4>
          <p className="appropriate">
          Through sophisticated technology and robust strategies, we track and manage every potential customer and engage with them to increase your conversions. 
          </p>
         </div>
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
        <div className="led">
        <img src={managers} className="inbo" alt="" />
          <h4>Market research </h4>
          <p className="appropriate">
          Our market research services use state-of-the-art tools to extract precise and insightful data on the latest market trends and customer behavior.
          </p>
         </div>
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
        <div className="led">
        <img src={bankruptcy} className="inbo" alt="" />
          <h4>Debt recovery</h4>
          <p className="appropriate">
          With our systematic and compliant approach, supported by advanced tracking and reporting tools, we devise effective collection strategies, resulting in improved cash flow for your business.
          </p>
         </div>
      </div>
    </div>
  </div>
</section>
<hr />
<section className="whyib">
  <div className="container">
    <div className="row text-center" id="Prefer">
      <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
        <h2>Reasons that set us  <br /><span className="lightspan">apart from others</span></h2>
      </div>
    </div>
    <div className="row" id="scalability">
    <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
    <div className="newroel">
      <img src={outboundpick2} className="trans" alt="" />
    </div>
     </div>
    <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
  <div className="outervoice">
    <div className="voice">
        <div className="rightnew">
        <h4>Advanced technology </h4>
        <p>We use state-of-art technology, including cutting-edge dialer systems, CRM integration and analytics tools to optimize outbound calls. 
</p>
      </div>
    
    </div>
   <div className="voice">
     <div className="rightnew">
      <h4> Specialized expertise </h4>
      </div>
      <p>When you partner with us, you get access to an experienced team of outbound calling warriors with expertise in sales, lead generation, customer engagement and much more. </p>
    
    </div>
    <div className="voice">
     <div className="rightnew">
      <h4>Security and compliance </h4>
      </div>
      <p>Our implementation of robust firewalls and top-tier encryption ensures a safe and world-class infrastructure for outbound support services. </p>
    
    </div>
   
    </div>
    </div>
    </div>
  </div>
</section>
<section className="techno">
  <div className="container">
  <div className="row text-center" id="c1">
      <div className="col-xxl-12 col-md-12 col-12">
      <h2 className="technology text-white ">Request a  <span class="green1">callback</span></h2>
      <p className="premier text-white">By partnering with us, you’re choosing a strategic partner dedicated to growing your business. Reach out to us to discover how we can help scale your business.</p>
      <div className="webser">
      
      <form action="Service.php" method="post" enctype="multipart/form-data">
          <ul>
          <li><input type="text" name="name" className="form-control"  placeholder="Name" required /></li>
           <li><input type="email" name="email" className="form-control" placeholder="Email"  required /></li>
           <li><input type="tel" name="phone" className="form-control" placeholder="Phone" required /></li>
            <li> <textarea id="message" title="Message" cols="30"  name='message'  rows="1"  maxlength="500"  placeholder="Enter Message" required /></li>
             <li><input type="submit" className="form-rol" defaultValue="send" /></li>
          </ul>
           </form>
                    </div>
      </div>
    </div>
   

  </div>
</section>
<section className="ibfeatures">
  <div className="container">
    <div className="row text-center">
      <div className="col-xxl-12 col-xl-12 col-md-12 col-12">    
       <h2>Key differentiators that help <br /> <span className="lightspan">us provide unmatched services</span></h2>
        <div className="sfs">
        <Tabs>
       <TabList>
      <Tab>Customized Solutions</Tab>
      <Tab>24*7 Support</Tab>
    </TabList>

    <TabPanel>
      <div className="row" id="tures">
      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12" id="keyto">
      <img src={ibservices} className="key1" alt="" />
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12" >
      <div className="Distrution">  
      <h3> Customized solutions to suit <span className="lightspan"> your business objectives</span></h3>
        <div className="leding">
             <h4>Tailored strategies for exceptional results </h4>
          <p className="appropriate">Working hand in hand, we design outbound support strategies that align with your goals and aspirations </p>
          <p className="appropriate">As your business evolves, we will refine our approach to tailor it to your evolving needs. </p>
          </div>
          <div className="bnrc">
          <Link
                to="/contact"
                className="btn ">
               Contact Us
              </Link> 
           </div>
      </div>
      </div>
      </div>
    </TabPanel>
    <TabPanel>
    <div className="row" id="tures">
    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">
      <div className="Distrution">
      <h3>Unwavering 24/7 support to ensure  <span className="lightspan"> continuous assistance </span></h3>
        <div className="leding">
          <h4>Round-the-clock availability</h4>
          <p className="appropriate">Empowered by cutting-edge technology and experienced specialists, we’re available round the clock to provide seamless outbound call support. </p>
          <p className="appropriate">With our 24/7 assistance, we ensure that your customers are within our reach, regardless of their time zone or location.  </p>
          </div>
          <div className="bnrc">
          <Link
                to="/contact"
                className="btn ">
               Contact Us
              </Link> 
           </div>
      </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12" id="keyto">
      <img src={support24} className="key1" alt="" />
      </div>
      </div>
    </TabPanel>
  </Tabs>
        </div>
      </div>
    </div>
   
  </div>
</section>
<section className="stsret">
  <div className="container">
    <div className="row text-center">
      <div className="col-xxl-12"> 
        <h2>Frequently asked<span class="lightspan"> questions  </span></h2>
        <p className="Get">Find answers to the most common questions about our outbound support services. If you have a question not answered here, please feel free to <a className="hightlightedtext" href="/contact">contact us.</a></p>

      </div>
      <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>In what ways can your outbound support services save me valuable time and money?</Accordion.Header>
        <Accordion.Body>
        Outsourcing your outbound calling needs to Logixm can provide your business with a strategic edge and enable you to focus on core business tasks. By entrusting us with these services, you can eliminate the need to train and hire an in-house team and maintain an infrastructure for them. This will help you minimize your operational costs and focus on your core business activities that drive growth. 

        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>What sets you apart from other competitors in the market?</Accordion.Header>
        <Accordion.Body>
        We stand out from a myriad of competitors due to our excellence across multiple fronts.
Our team comprises highly trained and professional specialists who have deep expertise and experience in handling customer interactions. Backed by advanced technology and infrastructure, we ensure optimal performance. Moreover, our flexible pricing structure caters to the diverse needs of our clients, offering them deserving value without compromising on quality. 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>What type of reporting do you provide?</Accordion.Header>
        <Accordion.Body>
        We provide highly detailed and customized reports to provide our clients with insights on important metrics such as ATT (average talk time) abandonment rates, first-time resolution and time-to-answer. 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>How do you ensure that the outbound calls are handled professionally?</Accordion.Header>
        <Accordion.Body>
        Our dedicated team comprises highly trained professionals who adhere to strict quality standards and best practices to ensure positive customer interactions and experiences. 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>What technology do you use for outbound support?</Accordion.Header>
        <Accordion.Body>
        We use robust technology and tools to streamline operations. This includes automated dialing systems, customer relationship management, call scripting tools, quality assurance software, and noise cancellation headphones to ensure clear communication. 

        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header>Can you customize your services to suit my specific business requirements?
</Accordion.Header>
        <Accordion.Body>
        Yes. We understand that every business is unique and distinctive. Therefore, we offer customized solutions to meet the specific requirements of your business. Whether you want to focus on lead generation, customer follow-ups or sales calls, we can tailor our strategies to suit your business requirements. 
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    </div>
  </div>
</section>
    </Layout>
  );
};

export default OutboundSupport;
