import React from "react";
import whitelogo from "../../img/whitelogo.png";
import { Link } from "react-router-dom";

const FooterThree = () => {
  return (
    <footer>
      <div className="footer-area-two footer-area-three">
        <div className="container">
          <div className="footer-top-two">
            <div className="row justify-content-center">
            <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
            <div className="footer-title">
              <div className="logo">
                    <Link to="/">
                  <img src={whitelogo} className="whitelogo" alt="" />
                    </Link>
                  </div>
                  <p className="Designing">
                  We’re your business growth partners, helping you save costs and improve ROI through strategic offshoring solutions. 
                  </p>
                  <div className="footer-social">
                    <ul className="list-wrap">
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li className="active">
                        <a href="#">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-google-plus-g"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>     
            </div>
            </div>
           <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
            <div className="footer-title">
              <h4>Important Links</h4>
              <div className="fw-link">
                    <ul className="list-wrap">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/AboutUs">Our Company</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact</Link>
                      </li>
                      <li>
                        <Link to="/Privacy-policy">Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to="/Terms-conditions">Terms & Conditions</Link>
                      </li>
                   
                    </ul>
                  </div>
            </div>
          </div>
          <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
            <div className="footer-title">
              <h4>Our Solutions</h4>
              <div className="fw-link">
                    <ul className="list-wrap">
                      <li>
                        <Link to="/Inbound-Support">Inbound Support</Link>
                      </li>
                      <li>
                        <Link to="/Outbound-Support">Outbound Support</Link>
                      </li>
                      <li>
                        <Link to="/Operations-Management">Operations Management</Link>
                      </li>
                      <li>
                        <Link to="/Lead-Generation ">Lead Generation Service</Link>
                      </li>
                      <li>
                        <Link to="/Sales">Sales</Link>
                      </li>
                      <li>
                        <Link to="/Chat-Email-Support">Chat & Email Support</Link>
                      </li>
                    </ul>
                  </div>
            </div>
          </div>
          <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
            <div className="footer-title">
              <h4>About Us</h4>
              <div className="footer-about">
                    <ul className="list-wrap">
                      <li>
                        <img src="/img/icon/phone_icon.svg" alt="" />
                        <ul className="small-list">
                          <li><a href="tel:+91 7837746949">+91 7837746949</a></li>
                          <li><a href="tel:+91 9915446949">+91 9915446949</a></li>
                        </ul>
                                             
                      </li>
                      <li>
                        <img src="/img/icon/mail_icon.svg" alt="" />
                        <a href="mailto:info@logixm.com">info@logixm.com</a>
                      </li>
                      <li>
                        <img src="/img/icon/loction_icon.svg" alt="" />
                        <span>IT-C-7, Netsmartz Center, 6th Floor, Sector 67 SAS Nagar, Punjab-160062</span>
                      </li>
                    </ul>
                  </div>
            </div>
          </div>
            </div>
          
          </div>

          <div className="footer-bottom-two">
            <div className="row align-items-center">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-12 col-md-12 col-12">
                <div className="copyright-text">
                  <p className="colyright">© <a  href="/">logixm.com</a> 2024 | All rights reserved</p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterThree;
