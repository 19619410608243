import React from "react";
import { Link } from "react-router-dom";
import sales from "../img/sales.png";
import inner4 from "../img/inner4.png";
import inner5 from "../img/inner5.png";
import vomessage from "../img/vomessage.png";
import dashboard from "../img/dashboard.png";

import ivr from "../img/ivr.png";
import knowledge from "../img/knowledge.png";
import managers from "../img/managers.png";
import onboarding from "../img/onboarding.png";
import client from "../img/client.png";
import mailgh from "../img/mailgh.png";
import Layout from "../layouts/Layout";
import bootomarrow from "../img/bootomarrow.png";
import key1 from "../img/key1.png";
import logi004 from "../img/logi004.png";
import Accordion from 'react-bootstrap/Accordion';
import star from "../img/star.png";
import 'react-tabs/style/react-tabs.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


const Sales = () => {
  return (
    <Layout header={1} footer={3} className="" mainClassName="">
    <section className="obinner">
    <div className="container">
        <div className="row">
        <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
        <div className="acent"> 
          <h1 className="dgjhj">Boost your sales and <span class="green1">  increase</span> your business revenue </h1>
          <p className="custmer1"> Maximize your sales growth with our customized solutions that connect you with high-quality leads and amplify your business revenue. </p>
          <ul className="in-ul">
            <li ><span><img src={star} className="star" alt="" /></span>  Proactive lead generation   </li>
            <li><span><img src={star} className="star" alt="" /></span>Targeted reach    </li>
            {/* <li><span><img src={star} className="star" alt="" /></span>Personalized sales approach   </li> */}
          </ul>

          <Link
                to="/contact"
                className="btn"
                           >
               Get started now
              </Link> 
          </div>
        </div>
          <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
            <img src={sales} className="gh" alt="" />
          </div>
        </div>
      </div>
    </section>

<section className="in-sec-1">
  <div className="container">
    <div className="row">
      <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
        <div className="brow">
          <h2 className="encompass">Solutions that drive real sales   </h2>
          <h3>We transform conversations into sales opportunities. </h3>
          <p>Our unparalleled solutions integrate persuasive communication, deep market insights and cost-effective strategies to fuel your sales growth and enhance ROI. </p>
        </div>
      </div>
    </div>
  </div>
  <img src={bootomarrow} className="bootomarrow" alt="" />
</section>
<section className="ib-serivces">
  <div className="container">
    <div className="row">
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
        <div className="led">
        <img src={dashboard} className="inbo" alt="" />
          <h4>Inbound sales </h4>
          <p className="appropriate">We manage customer interactions, guiding your potential customers through the sales process and converting inquiries into sales. </p>
       
        </div>
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
        <div className="led">
        <img src={vomessage} className="inbo" alt="" />
          <h4>Outbound sales </h4>
          <p className="appropriate">Through cold calling, we initiate conversations with targeted leads, helping you acquire new customers and secure more sales. </p>
       
        </div>
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
        <div className="led">
        <img src={ivr} className="inbo" alt="" />
          <h4>Cross-selling </h4>
          <p className="appropriate">
          We improve your revenue opportunities by effectively cross-selling additional products or services to your existing customers. 
          </p>
         </div>
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
        <div className="led">
        <img src={knowledge} className="inbo" alt="" />
          <h4>Upselling</h4>
          <p className="appropriate">
          We drive upsells by persuading your existing customers to upgrade to premium versions or top-tier services. 
          </p>
         </div>
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
        <div className="led">
        <img src={managers} className="inbo" alt="" />
          <h4>Market research </h4>
          <p className="appropriate">
          Our team gathers valuable insights about your target audience, customer feedback, and industry trends through extensive market research.  . 
          </p>
         </div>
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
        <div className="led">
        <img src={onboarding} className="inbo" alt="" />
          <h4>Customer retention </h4>
          <p className="appropriate">
          We connect with your existing customers to address any issues and identify opportunities for cross-selling and upselling to improve customer satisfaction. 
          </p>
         </div>
      </div>
    </div>
  </div>
</section>
<hr />
<section className="whyib">
  <div className="container">
    <div className="row text-center" id="Prefer">
      <div className="col-xxl-12 col-xl-12 col-md-12 col-12"> 
        <h2>Exceptional Qualities that  <br /><span className="lightspan">make us stand out </span></h2>
      </div>
    </div>
    <div className="row" id="scalability">
    <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
    <div className="newroel">
      <img src={inner4} className="bann" alt="" />
    </div>
     </div>
    <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
  <div className="outervoice">
    <div className="voice">
        <div className="rightnew">
        <h4>Expert team  </h4>
        <p>Our experienced team is well-versed in sales techniques and rigorously trained to meet your customer’s unique needs.  
</p>
      </div>
    
    </div>
   <div className="voice">
     <div className="rightnew">
      <h4> Advanced technology  </h4>
      </div>
      <p>We leverage the latest tools and technology to enhance every aspect of your sales process for increased efficiency. </p>
    
    </div>
    <div className="voice">
     <div className="rightnew">
      <h4>Customized solutions</h4>
      </div>
      <p>We provide personalized solutions that align with your specific business needs and objectives. </p>
    
    </div>
   
    </div>
    </div>
    </div>
  </div>
</section>
<section className="techno">
  <div className="container">
  <div className="row text-center" id="c1">
      <div className="col-xxl-12 col-md-12 col-12">
      <h2 className="technology text-white ">Request a  <span class="green1">callback</span></h2>
      <p className="premier text-white">By partnering with us, you’re choosing a strategic partner dedicated to growing your business. Reach out to us to discover how we can help scale your business.</p>
      <div className="webser">
      
      <form action="Service.php" method="post" enctype="multipart/form-data">
          <ul>
          <li><input type="text" name="name" className="form-control"  placeholder="Name" required /></li>
           <li><input type="email" name="email" className="form-control" placeholder="Email"  required /></li>
           <li><input type="tel" name="phone" className="form-control" placeholder="Phone" required /></li>
            <li> <textarea id="message" title="Message" cols="30"  name='message'  rows="1"  maxlength="500"  placeholder="Enter Message" required /></li>
             <li><input type="submit" className="form-rol" defaultValue="send" /></li>
          </ul>
           </form>
                    </div>
      </div>
    </div>
   

  </div>
</section>
<section className="ibfeatures">
  <div className="container">
    <div className="row text-center">
      <div className="col-xxl-12 col-xl-12 col-md-12 col-12">      
       <h2>Level up your sales game <br /> <span className="lightspan">with our expertise</span></h2>
        <div className="sfs">
        <Tabs>
       <TabList>
      <Tab>Result driven strategies </Tab>
      <Tab>Personalized approach </Tab>
    </TabList>

    <TabPanel>
      <div className="row" id="tures">
      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12" id="keyto">
      <img src={inner5} className="key1" alt="" />
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12" >
      <div className="Distrution">  
      <h3> Strategies focused on driving   <span className="lightspan"> impactful sales growth</span></h3>
        <div className="leding">
                <h4>Result-oriented sales techniques </h4>
          <p className="appropriate">Our tailored solutions blend time-proven tactics and trusted strategies to stimulate your sales growth and revenue. </p>
          <p className="appropriate">Our sales techniques are designed to align with your audience's needs and build authentic connections to improve conversion rates.  </p>
          </div>
          <div className="bnrc">
          <Link
                to="/contact"
                className="btn ">
               Contact Us
              </Link> 
           </div>
      </div>
      </div>
      </div>
    </TabPanel>
    <TabPanel>
    <div className="row" id="tures">
      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">
      <div className="Distrution">
      <h3>Personalized approach  </h3>
        <div className="leding">
            <h4>Targeted reach to increase conversions </h4>
          <p className="appropriate"> We strive to build genuine relationships with your customers, understanding their pain points and providing solutions to resolve their issues. </p>
          <p className="appropriate">Our sales team is thoroughly trained to deliver customized sales pitches that resonate with your target audience and nurture deeper connections to amplify conversions.</p>
          </div>
          <div className="bnrc">
          <Link
                to="/contact"
                className="btn ">
               Contact Us
              </Link> 
           </div>
      </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12" id="keyto">
      <img src={logi004} className="key1" alt="" />
      </div>
      </div>
    </TabPanel>
  </Tabs>
        </div>
      </div>
    </div>
   
  </div>
</section>
<section className="stsret">
  <div className="container">
    <div className="row text-center">
      <div className="col-xxl-12"> 
        <h2>Frequently asked<span class="lightspan"> questions  </span></h2>
        <p className="Get">Find answers to the most common questions about our services. If you have a question not answered here, please feel free to <a className="hightlightedtext" href="/contact">contact us.</a></p>

      </div>
      <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>What types of sales support services do you offer?</Accordion.Header>
        <Accordion.Body>
        At Logixm, we offer a comprehensive range of sales support services, including lead generation, appointment setting, customer follow-up calls, cross-selling and upselling, market research, and customer retention to deliver tangible results for our clients.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>How can outsourcing sales support services to Logixm benefit my business?
</Accordion.Header>
        <Accordion.Body>
        You can enjoy a wide range of benefits by outsourcing sales support services to Logixm, including cost savings on overheads, access to a specialized team of sales representatives, improved scalability, better efficiency, and enhanced customer satisfaction.

        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>How do you ensure the quality of sales support services provided by your sales team? </Accordion.Header>
        <Accordion.Body>
        Our sales representatives are rigorously trained to use the latest and innovative sales techniques. Moreover, we consistently monitor the performance of our team and refine our strategies for better results. Also, we comply with industry best practices to maximize sales growth and revenue for our clients.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>How do you handle confidentiality and data security concerns?</Accordion.Header>
        <Accordion.Body>
        We take confidentiality and data security seriously and have robust measures in place to protect your sensitive information. This includes data encryption, access controls, compliance with relevant regulations, and the use of confidentiality agreements and non-disclosure agreements (NDAs) as needed to protect your sensitive information. 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>How do you handle the leads generated throughout the process?</Accordion.Header>
        <Accordion.Body>
        We actively engage and nurture the leads through the sales funnel. Our team employs a combination of techniques, including cold calling and emails to stay connected with the leads and guide them through the buying process. Furthermore, our sales representatives are trained to follow up on leads to meet their specific needs. Also, we use cutting-edge tools and techniques to keep track of interactions, monitor progress and ensure timely follow-up with the leads.

        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header>What level of personalization can I expect from your team while engaging with my potential customers?
</Accordion.Header>
        <Accordion.Body>
        We provide a high level of personalization to ensure that each interaction with your potential customers matches their needs and preferences. Our personalized approach includes tailored messaging, customized solutions, individualized follow-up and targeted outreach to boost your sales.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    </div>
  </div>
</section>
    </Layout>
  );
};

export default Sales;
