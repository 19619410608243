import React, { useEffect } from 'react';
import Typed from 'typed.js';

const HeroBanner = () => {
  useEffect(() => {
    const section = document.querySelector('.investment_support .wrapper');

    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('active');
                entry.target.classList.remove('hidden');
                // If you want to observe only once, uncomment the following line
                // observer.unobserve(entry.target);
            } else {
                entry.target.classList.add('hidden');
                entry.target.classList.remove('active');
            }
        });
    });
    
    observer.observe(section);
    
  }, []);

  return (
<section className="investment_support">
  <div className="container">
    <div className="wrapper">
      <div className="focus_block">
        . <div className="focus_item" />
        <div className="focus_item" />
        <div className="focus_item" />
        <div className="focus_item" />
      </div>
      <div className="row">
        <div className="item col-md-6 col-sm-12 col-12">
          <div className="item_wrap">
            <div className="head">
              <h4>
                <span>Client-centric </span>
              </h4>
               </div>
            <div className="text">
              <p>
              We consider our amiable clients as our partners in our success. That’s why we go over and beyond to deliver the finest services based on their specific business goals and objectives. 
                <br />
                <br />
                We take the time to understand your business, challenges and objectives to tailor our solutions to perfectly align with your vision. 
              </p>
            </div>
          </div>
        </div>
        <div className="item col-md-6 col-sm-12 col-12">
          <div className="item_wrap">
            <div className="head">
              <h4>
                <span>Unmatched quality </span>
              </h4>
              </div>
            <div className="text">
              <p>
              We’re dedicated to providing high-quality solutions to our clients. Therefore, our team relentlessly pushes boundaries to exceed our client’s expectations. 

                <br />
                <br />
                Utilizing the latest technological innovations and improved processes, we work collaboratively with our clients to improve their business processes. 

              </p>
            </div>
          </div>
        </div>
        <div className="item col-md-6 col-sm-12 col-12">
          <div className="item_wrap">
            <div className="head">
              <h4>
                <span>Ethical approach </span>
              </h4>
                 </div>
            <div className="text">
              <p>
              We hold ourselves to the highest ethical standards of honesty, fairness and respect in all our interactions with our clients, fostering an environment of trust and collaboration. 
                <br />
                <br />
We prioritize open communication with our valued partners, ensuring they are informed to make the best decisions. 

              </p>
            </div>
          </div>
        </div>
        <div className="item col-md-6 col-sm-12 col-12">
          <div className="item_wrap">
            <div className="head">
              <h4>
                <span>Innovation </span>
              </h4>
              </div>
            <div className="text">
              <p>
              Innovation is the cornerstone of our success that drives us to explore new technologies and practices to deliver greater value to our clients. 
                <br />
                <br />
                From leveraging advanced technology to creating a culture of creativity, we seek out opportunities to innovate and differentiate ourselves from others. 

              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

  );
};

export default HeroBanner;
