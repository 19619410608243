import React from "react";
import { Link } from "react-router-dom";
import chatandemail from "../img/chatandemail.png";
import inner9 from "../img/inner9.png";
import ibpick1 from "../img/ibpick1.png";
import vomessage from "../img/vomessage.png";
import ivr from "../img/ivr.png";
import knowledge from "../img/knowledge.png";
import managers from "../img/managers.png";
import onboarding from "../img/onboarding.png";
import girls002 from "../img/girls002.png";
import mailgh from "../img/mailgh.png";
import Layout from "../layouts/Layout";
import bootomarrow from "../img/bootomarrow.png";
import logi003 from "../img/logi003.png";
import trans from "../img/trans.png";
import Accordion from 'react-bootstrap/Accordion';
import star from "../img/star.png";
import 'react-tabs/style/react-tabs.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


const ChatEmailSupport = () => {
  return (
    <Layout header={1} footer={3} className="" mainClassName="">
    <section className="obinner">
    <div className="container">
        <div className="row">
        <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
        <div className="acent"> 
          <h1 className="dgjhj">  Elevate customer <span class="green1">  experiences</span> with expedited support  </h1>
          <p className="custmer1"> We help businesses engage with their customers, resolve their queries and improve their overall satisfaction level. </p>
          <ul className="in-ul">
            <li ><span><img src={star} className="star" alt="" /></span>  Quick response time  </li>
            <li><span><img src={star} className="star" alt="" /></span>Real-time assistance </li>
            {/* <li><span><img src={star} className="star" alt="" /></span>Improved productivity    </li> */}
          </ul>

          <Link
                to="/contact"
                className="btn "
                       >
                Contact Us
              </Link> 
          </div>
        </div>
          <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
            <img src={chatandemail} className="gh" alt="" />
          </div>
        </div>
      </div>
    </section>

<section className="in-sec-1">
  <div className="container">
    <div className="row">
      <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
        <div className="brow">
          <h2 className="encompass"> Email & chat support services we offer  </h2>
          <h3>Customized, and flexible solutions that align with your business needs.  </h3>
          <p>Struggling to handle high volumes of queries from your customers? We have you covered. Explore our vast range of services that will help you efficiently handle the wide range of queries from your customers. </p>
        </div>
      </div>
    </div>
  </div>
  <img src={bootomarrow} className="bootomarrow" alt="" />
</section>
<section className="ib-serivces">
  <div className="container">
    <div className="row">
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
        <div className="led">
        <img src={ibpick1} className="inbo" alt="" />
          <h4>Customer issue resolution  </h4>
          <p className="appropriate">We provide timely responses and resolutions to your customer inquiries via email and chat platforms, ensuring their satisfaction.  </p>
       
        </div>
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
        <div className="led">
        <img src={vomessage} className="inbo" alt="" />
          <h4>Product & service assistance  </h4>
          <p className="appropriate">Through our email and chat support, we provide customers with detailed product knowledge to help them make well-informed purchasing decisions.</p>
       
        </div>
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
        <div className="led">
        <img src={ivr} className="inbo" alt="" />
          <h4>Technical issue troubleshooting </h4>
          <p className="appropriate">
          We expertly assist customers in troubleshooting technical issues by providing detailed guidance to resolve them.  
          </p>
         </div>
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
        <div className="led">
        <img src={knowledge} className="inbo" alt="" />
          <h4>Follow-up email support  </h4>
          <p className="appropriate">
          Our dedicated team provides regular and meaningful follow-up support to foster customer retention and build loyalty. 
          </p>
         </div>
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
        <div className="led">
        <img src={managers} className="inbo" alt="" />
          <h4>Verification support </h4>
          <p className="appropriate">
          We are experts in handling verification emails, including customer information, identity verification, and account details to ensure your business security. 
          </p>
         </div>
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
        <div className="led">
        <img src={onboarding} className="inbo" alt="" />
          <h4>Escalation email support </h4>
          <p className="appropriate">
          Our team is proficient in handling escalated issues professionally and quickly, protecting your business reputation and customer relationships. 

          </p>
         </div>
      </div>
    </div>
  </div>
</section>
<hr />
<section className="whyib">
  <div className="container">
    <div className="row text-center" id="Prefer">
      <div className="col-xxl-12 col-xl-12 col-md-12 col-12">     
        <h2>Reasons contributing to our <br /><span className="lightspan">exceptional performance </span></h2>
      </div>
    </div>
    <div className="row" id="scalability">
    <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
    <div className="newroel">
      <img src={inner9} className="bann" alt="" />
    </div>
     </div>
    <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
  <div className="outervoice">
    <div className="voice">
        <div className="rightnew">
        <h4>Timely responses </h4>
        <p>
        We leverage advanced technologies to analyze emails and chats from customers to minimize response time and promptly address their concerns without delay.
</p>
      </div>
    
    </div>
   <div className="voice">
     <div className="rightnew">
      <h4> Cutting-edge infrastructure </h4>
      </div>
      <p>
      Our team operates from modern workspaces equipped with cutting-edge communication tools to deliver superior services in real-time. </p>
    
    </div>
    <div className="voice">
     <div className="rightnew">
      <h4> Proficient agents </h4>
      </div>
      <p>Our dedicated agents are pre-vetted and thoroughly trained on your product and service manuals to provide outstanding support to your customers. 
 </p>
    
    </div>
   
    </div>
    </div>
    </div>
  </div>
</section>
<section className="techno">
  <div className="container">
  <div className="row text-center" id="c1">
      <div className="col-xxl-12 col-md-12 col-12">
      <h2 className="technology text-white ">Request a  <span class="green1">callback</span></h2>
      <p className="premier text-white">By partnering with us, you’re choosing a strategic partner dedicated to growing your business. Reach out to us to discover how we can help scale your business.</p>
      <div className="webser">
      
      <form action="Service.php" method="post" enctype="multipart/form-data">
          <ul>
          <li><input type="text" name="name" className="form-control"  placeholder="Name" required /></li>
           <li><input type="email" name="email" className="form-control" placeholder="Email"  required /></li>
           <li><input type="tel" name="phone" className="form-control" placeholder="Phone" required /></li>
            <li> <textarea id="message" title="Message" cols="30"  name='message'  rows="1"  maxlength="500"  placeholder="Enter Message" required /></li>
             <li><input type="submit" className="form-rol" defaultValue="send" /></li>
          </ul>
           </form>
                    </div>
      </div>
    </div>
   

  </div>
</section>
<section className="ibfeatures">
  <div className="container">
    <div className="row text-center">
      <div className="col-xxl-12 col-xl-12 col-md-12 col-12">  
       <h2>Distinguished features that <span className="lightspan">help us stand out   </span></h2>
        <div className="sfs">
        <Tabs>
       <TabList>
      <Tab>Quality monitoring   </Tab>
      <Tab> Unmatched transparency </Tab>
    </TabList>

    <TabPanel>
      <div className="row" id="tures">
      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12" id="keyto">
      <img src={girls002} className="trans2" alt="" />
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12" >
      <div className="Distrution">  
      <h3>Effectively monitoring the quality of   <span className="lightspan">every email and chat response</span></h3>
        <div className="leding">
          <h4>Advanced quality monitoring systems    </h4>
          <p className="appropriate">By harnessing the power of state-of-the-art quality monitoring systems, we analyze and evaluate the quality of every email and chat response.  </p>
          <p className="appropriate">This ensures your customers receive relevant and ideal responses tailored to their specific queries.  </p>
          </div>
          <div className="bnrc">
          <Link
                to="/contact"
                className="btn ">
               Contact Us
              </Link> 
           </div>
      </div>
      </div>
      </div>
    </TabPanel>
    <TabPanel>
    <div className="row" id="tures">
      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">
      <div className="Distrution">
      <h3>Providing valuable insights to evaluate every customer interactions    </h3>
        <div className="leding">

          <h4>Transparency at the core  </h4>
          <p className="appropriate">  All the details of our chat and email support process are stored in our integrated customer relationship management system.  </p>
          <p className="appropriate">This allows our clients to evaluate customer interactions based on specific parameters such as response time, customer satisfaction ratings and issue resolution efficiency.  </p>
          </div>
          <div className="bnrc">
          <Link
                to="/contact"
                className="btn ">
               Contact Us
              </Link> 
           </div>
      </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12" id="keyto">
      <img src={logi003} className="trans2" alt="" />
      </div>
      </div>
    </TabPanel>
  </Tabs>
        </div>
      </div>
    </div>
   
  </div>
</section>
<section className="stsret">
  <div className="container">
    <div className="row text-center">
      <div className="col-xxl-12"> 
        <h2>Frequently asked<span class="lightspan"> questions  </span></h2>
        <p className="Get">Find answers to the most common questions about our email and chat support services. If you have a question not answered here, please feel free to <a className="hightlightedtext" href="/contact">contact us.</a></p>

      </div>
      <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>What measures do you take to ensure data security and confidentiality during chat and email interactions?</Accordion.Header>
        <Accordion.Body>
        We adhere to strict security protocols and encryption standards to safeguard customer data and ensure confidentiality throughout chat and email interactions. So you can rest assured that your sensitive data is in safe hands.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Do your agents undergo any type of training to handle customer inquiries?
</Accordion.Header>
        <Accordion.Body>
        Yes. Our agents undergo comprehensive training programs to equip themselves with your products and services information, communication skills, and customer service best practices to handle inquiries diligently and effectively. 

        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>How do your agents handle escalations and complex customer issues? </Accordion.Header>
        <Accordion.Body>
        Our agents are trained to handle escalations and complex issues efficiently. Also, we have streamlined escalation procedures to ensure timely resolution of escalated issues and preserve your business reputation. 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header> Are your chat and email support services available round the clock?</Accordion.Header>
        <Accordion.Body>
        Yes. We provide highly effective chat and email support services 24/7 to ensure your customers receive timely assistance for their issues irrespective of time zone or location. 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>How do you measure the performance of your email and chat support? </Accordion.Header>
        <Accordion.Body>
        In order to evaluate the effectiveness of our services, we track and measure key performance indicators such as response time, resolution rate, customer satisfaction scores and feedback. This helps us to enhance the quality of our services for increased customer satisfaction. 

        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header>Why should I outsource my email and chat support services to Logixm?
</Accordion.Header>
        <Accordion.Body>
        Outsourcing your email and chat support services to Logixm can help you save overhead costs while ensuring superior services. Furthermore, we are backed by an experienced team trained in best practices to manage email queries, address customer issues and resolve escalated cases. Additionally, we leverage advanced technologies and tools to improve efficiency and enhance customer experiences. 

        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    </div>
  </div>
</section>
    </Layout>
  );
};

export default ChatEmailSupport;
