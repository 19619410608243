import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../layouts/Layout";
import morph from "../../img/morph.png";
import target from  "../../img/target.png";
import visionary from  "../../img/visionary.png";
import currency from  "../../img/currency.png";
import star from "../../img/star.png";
import comapnypick1 from "../../img/comapnypick1.png";
import Processpick1 from "../../img/Processpick1.png";
import Processpick2 from "../../img/Processpick2.png";
import Processpick3 from "../../img/Processpick3.png";
import Processpick4 from "../../img/Processpick4.png";
import headphone1 from "../../img/headphone1.png";
import girl from "../../img/girl.png";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import HeroBanner from "../../components/HeroBanner";
import bootomarrow from "../../img/bootomarrow.png";

const AboutUs = () => {
  return (
    
    <Layout header={1} footer={3} className="" mainClassName="">
    <section className="aboutbner">
      <div className="container">
        <div className="row">
          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
            <div className="abnr">
              <h1 className="awesome">Logixm</h1>
              <p>We provide cutting-edge offshoring solutions and best-in-class services by leveraging innovative approaches and technologies to optimize efficiency, reduce costs and drive business growth for our amiable clients</p>
              <ul className="inl">
            <li ><span><img src={star} className="star" alt="" /></span>10+ years of growing businesses</li>
            <li><span><img src={star} className="star" alt="" /></span>60+esteemed clients </li>
            <li><span><img src={star} className="star" alt="" /></span>100+skilled professionals </li>
          </ul>
        
          <div className="bnrcta">
           <a href="/contact"> Get started now </a>
                     </div>
            </div>
          </div>
        </div>
      </div>
      <img src={headphone1} className="headphone1" alt="" />
      <img src={morph} className="morph" alt="" />
    </section>
    <section className="in-sec-1">
  <div className="container">
    <div className="row">
      <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
        <div className="brow"> 
          <h2 className="encompass"> We optimize business processes<br /> to boost productivity </h2>
          <h3>Infusing technology and people to help businesses thrive </h3>
          <p>By seamlessly blending the strengths of people and technology, we unlock new levels of 
efficiency and innovation, cultivating a customer-centric environment that creates meaningful interactions and leads to sustainable business growth. </p>
        </div>
      </div>
    </div>
  </div>
  <img src={bootomarrow} className="bootomarrow" alt="" />
</section>
 <section className="rule">
  <div className="container">
    <div className="row">
      <div className="col-xxl-5 col-md-5 col-12">
        <img src={comapnypick1} className="signle" alt="" />
      </div>
      <div className="col-xxl-7 col-md-7 col-12">
      <div className="typting">  
      <h2>Your strategic partners dedicated <span className="lightspan">to elevating your business</span></h2>
      <p className="typesetting">At Logixm, we’re your trusted partners in driving tangible results for your businesses, including reduced operating costs, enhanced customer satisfaction, maximized sales and revenue growth.</p>

      <p className="typesetting">Through our comprehensive suite of technology-driven solutions and in-house expertise, we empower businesses to unlock cutting-edge customer experiences, fostering organic growth for our clients. Partner with us to improve the efficiency of your business processes and  propel your business to new heights. .</p>
         <ul className="when">
          <li><span> <img src={star} className="star" alt="" /></span>Technology-enabled solutions</li>
          <li><span> <img src={star} className="star" alt="" /></span>Expertise across diverse industries</li>
          <li><span> <img src={star} className="star" alt="" /></span>Global reach </li>
        </ul>
        
          </div>
      </div>
     </div>
      </div>
  </section>
 <section className="easier">
  <div className="container">
    <div className="row text-center">
      <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12"> 
      <h2>Our vision, mission <span className="lightspan"> and commitment </span></h2>
      <p className="containing">As a leader in business elevation, we strive to create dynamic partnerships that fuel growth, ignite transformation and pioneer a new era of success.</p>
      </div>
      <div className="row" id="Emphires">
    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
    <div className="Emphires">
    <img src={target} className="target" alt="" />
    <h4 className="Choose1">Our Mission</h4>
    <div class="grey-line"></div>
    <div class="green-underline"></div>
    <p className="caring ">To harness cutting-edge technology and best practices to deliver high-quality solutions to our clients, empowering them to achieve their business goals </p>
    </div>
    </div>
 
    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
    <div className="Emphires">
    <img src={visionary} className="target" alt="" />
    <h4 className="Choose1">Our vision </h4>
    <div class="grey-line"></div>
    <div class="green-underline"></div>
    <p className="caring ">To be a partner of choice for businesses seeking sustainable long-term growth and to build meaningful relationships with clients based on transparency and integrity. </p>
    </div>
    </div>
    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
    <div className="Emphires">
    <img src={currency} className="target" alt="" />
    <h4 className="Choose1"> Our commitment </h4>
    <div class="grey-line"></div>
    <div class="green-underline"></div>
    <p className="caring ">We are committed to delivering outstanding value to our clients through unparalleled expertise and focus on quality. </p>
    </div>
    </div>
  </div>
    </div>
  </div>
</section>

<HeroBanner />
<section className="heromobile">
  <div className="container">
    <div className="row">
      <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
        <div className="herobox">
         <h2 className="text-white"><span className="Saving1">1.</span> Client-centric </h2>
         <div className="herotext">
         <p className="text-white">
         We consider our amiable clients as our partners in our success. That’s why we go over and beyond to deliver the finest services based on their specific business goals and objectives. 

                <br />
                <br />
                We take the time to understand your business, challenges and objectives to tailor our solutions to perfectly align with your vision.
              </p>
         </div>
         <p></p>
        </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
        <div className="herobox">
         <h2 className="text-white"><span className="Saving1">2.</span>Unmatched quality</h2>
         <div className="herotext">
         <p className="text-white">
         We’re dedicated to providing high-quality solutions to our clients. Therefore, our team relentlessly pushes boundaries to exceed our client’s expectations. 
                <br />
                <br />
                Utilizing the latest technological innovations and improved processes, we work collaboratively with our clients to improve their business processes.
              </p>
         </div>
         <p></p>
        </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
        <div className="herobox">
         <h2 className="text-white"><span className="Saving1">3.</span> Ethical approach </h2>
         <div className="herotext">
         <p className="text-white">
         We hold ourselves to the highest ethical standards of honesty, fairness and respect in all our interactions with our clients, fostering an environment of trust and collaboration. 
                <br />
                <br />
                We prioritize open communication with our valued partners, ensuring they are informed to make the best decisions. 
              </p>
         </div>
         <p></p>
        </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
        <div className="herobox">
         <h2 className="text-white"><span className="Saving1">4.</span> Innovation </h2>
         <div className="herotext">
         <p className="text-white">
         Innovation is the cornerstone of our success that drives us to explore new technologies and practices to deliver greater value to our clients. 
                <br />
                <br />
                From leveraging advanced technology to creating a culture of creativity, we seek out opportunities to innovate and differentiate ourselves from others. 
              </p>
         </div>
         <p></p>
        </div>
      </div>
    </div>
  </div>
</section>
<section className="work">
  <div className="container">
    <div className="row text-center">
      <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
      <h4 className="subheading">Key differentiators </h4>
        <h2>Check Out Our<span className="lightspan"> distinctive features </span> </h2>
        <p className="Ehires">Our distinctiveness stems from a combination of unique features that set us apart from the myriad of competitors out there. </p>
      </div>
    </div>
    <div className="row">
    <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
    <div className="reprehenderit">
    <img src={Processpick2} className="process1" alt="" />
      <h4>Modern workplace </h4>
      <p>Our state-of-art facility can accommodate 100+ workstations.</p>
    </div>
    </div>
    <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
    <div className="reprehenderit">
    <img src={Processpick1} className="process1" alt="" />
    
      <h4>Robust infrastructure </h4>
      <p>We leverage scalable infrastructure to support the growth of your business. </p>
    </div>
    </div>
    <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
    <div className="reprehenderit">
      <img src={Processpick3} className="process1" alt="" />
      <h4>Rigorously trained team </h4>
      <p>Our team is trained to deliver exceptional service that exceeds expectations.</p>
    </div>
    </div>
    <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
    <div className="reprehenderit">
      <img src={Processpick4} className="process1" alt="" />
      <h4>Customized solutions</h4>
      <p>We offer customized solutions tailored to your unique needs. </p>
    </div>
    </div>
    </div>
  </div>
</section>
<section className="signlegirl">
<img src={girl} className="girl" alt="" />
</section>
<section className="techno">
  <div className="container">
  <div className="row text-center" id="c1">
      <div className="col-xxl-12 col-md-12 col-12"> 
      <h2 className="technology text-white ">Request a <span class="green1">callback </span></h2>
      <p className="premier text-white">By partnering with us, you’re choosing a strategic partner dedicated to growing your business. Reach out to us to discover how we can help scale your business.</p>
      <div className="webser">
      <form action="Service.php" method="post" enctype="multipart/form-data">
          <ul>
          <li><input type="text" name="name" className="form-control"  placeholder="Name" required /></li>
           <li><input type="email" name="email" className="form-control" placeholder="Email"  required /></li>
           <li><input type="tel" name="phone" className="form-control" placeholder="Phone" required /></li>
            <li> <textarea id="message" title="Message" cols="30"  name='message'  rows="1"  maxlength="500"  placeholder="Enter Message" required /></li>
             <li><input type="submit" className="form-rol" defaultValue="send" /></li>
          </ul>
           </form>
                    </div>
      </div>
    </div>
   

  </div>
</section>
    </Layout>
  );
};

export default AboutUs;
