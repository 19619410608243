import React from "react";
import pick1 from "../../img/pick1.png";
import pick4 from "../../img/pick4.png";
import pick5 from "../../img/pick5.png";
import pick6 from "../../img/pick6.png";
import bootomarrow from "../../img/bootomarrow.png";
import bnrs from "../../img/bnrs.mp4";







const BannerOne = () => {
  return (
    <section className="banner-area banner-bg"  >

      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <video src={bnrs} autoPlay loop muted />
            <div className='overlay' />
            <div className="banner-content">
              <h2 className="title wow fadeInUp" data-wow-delay=".4s">
                We scale & expand <br /><span className="Masterys">businesses to drive </span>
              </h2>
              <span>
                <h3 className="awesome">revenue growth </h3>

              </span>

              <p className="Been">We partner with our clients to convert client interactions into strategic growth avenues, resulting in increased sales, better productivity and enhanced ROI.
              </p>
              <div className="bnrcta">
                <a href="/contact">Get Started Now</a>
              </div>
              <div className="banner-cot"></div>
            </div>
          </div>


        </div>
      </div>
      {/* <img src={bootomarrow} className="bootomarrow" alt="" /> */}

    </section>
  );
};

export default BannerOne;
