import { useEffect } from "react";
import { useLocation, Routes, Route } from "react-router-dom";
import WOW from "wow.js";
import CreativeAgency from "./pages/Home/CreativeAgency";
import PersonalPortfolio from "./pages/Home/PersonalPortfolio";
import DigitalAgency from "./pages/Home/DigitalAgency";
import AboutUs from "./pages/About/AboutUs";
import AboutMe from "./pages/About/AboutMe";
import TeamPage from "./pages/Team/TeamPage";
import TeamPageDetails from "./pages/Team/TeamPageDetails";
import PortfolioDetailsPage from "./pages/PortfolioDetailsPage";
import ServicesDetailsPage from "./pages/ServicesDetailsPage";
import ContactPage from "./pages/ContactPage";
import BlogPage from "./pages/Blog/BlogPage";
import BlogDetailsPage from "./pages/Blog/BlogDetailsPage";
import Home from "./pages/Home";
import InboundSupport from "./pages/Inbound-Support";
import OutboundSupport from "./pages/Outbound-Support";
import LeadGeneration from "./pages/Lead-Generation";
import Sales from "./pages/Sales";
import OperationsManagement from "./pages/Operations-Management";
import ChatEmailSupport from "./pages/Chat-Email-Support";
import Privacypolicy  from "./pages/Privacy-policy";
import Termsconditions from "./pages/Terms-conditions";
import Thankyou from "./pages/Thankyou";

function App() {
  useEffect(() => {
    const wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: false,
      live: true,
    });
    wow.init();
  }, []);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
   

      <Routes>
      <Route path="/Thankyou" element={<Thankyou />} />
      <Route path="/Terms-conditions" element={<Termsconditions />} />
      <Route path="/Privacy-policy" element={<Privacypolicy />} />
      <Route path="/Chat-Email-Support" element={<ChatEmailSupport />} />
      <Route path="/Operations-Management" element={<OperationsManagement />} />
      <Route path="/Sales" element={<Sales />} />
      <Route path="/Lead-Generation" element={<LeadGeneration />} />
      <Route path="/Outbound-Support" element={<OutboundSupport />} />
      <Route path="/Inbound-Support" element={<InboundSupport />} />
      <Route path="/" element={<Home />} />
        <Route path="/CreativeAgency" element={<CreativeAgency />} />
        <Route path="/home-two" element={<PersonalPortfolio />} />
        <Route path="/home-three" element={<DigitalAgency />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/about-me" element={<AboutMe />} />
        <Route path="/team" element={<TeamPage />} />
        <Route path="/team-details" element={<TeamPageDetails />} />
        <Route path="/project-details" element={<PortfolioDetailsPage />} />
        <Route path="/services-details" element={<ServicesDetailsPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blog-details" element={<BlogDetailsPage />} />
      </Routes>
    </>
  );
}

export default App;
