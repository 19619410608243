import React from "react";
import { Link } from "react-router-dom";
import inboundbnr from "../img/inboundbnr.png";
import IB2 from "../img/IB2.png";
import logi001 from "../img/logi001.png";
import innre02 from "../img/innre02.png";
import order from "../img/order.png";
import inbound from "../img/inbound.png";
import userlead from "../img/userlead.png";
import crossselling from "../img/crossselling.png";
import desksupport from "../img/desksupport.png";
import inboundpic2 from "../img/inboundpic2.png";
import onboarding from "../img/onboarding.png";
import client from "../img/client.png";
import mailgh from "../img/mailgh.png";
import Layout from "../layouts/Layout";
import bootomarrow from "../img/bootomarrow.png";
import key1 from "../img/key1.png";
import support24 from "../img/support24.png";
import Accordion from 'react-bootstrap/Accordion';
import star from "../img/star.png";
import 'react-tabs/style/react-tabs.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


const InboundSupport = () => {
  return (
    <Layout header={1} footer={3} className="" mainClassName="">
      <section className="obinner">
    <div className="container">
        <div className="row">
        <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
        <div className="acent">
          <h1 className="dgjhj">Enhance your <span class="green1">customer loyalty</span> with Seamless inbound solutions</h1>
          <p className="custmer1">Build stronger customer relationships while reducing overhead operating costs with our inbound support. </p>
          <ul className="in-ul">
            <li ><span><img src={star} className="star" alt="" /></span> 24/7 call handling </li>
            <li><span><img src={star} className="star" alt="" /></span>Advanced call routing </li>
            <li><span><img src={star} className="star" alt="" /></span>Trained agents </li>
          </ul>

          <Link
                to="/contact"
                className="btn"
                            >
               Get started now
              </Link> 
          </div>
        </div>
          <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
            <img src={inboundbnr} className="gh" alt="" />
          </div>
        </div>
      </div>
    </section>

<section className="in-sec-1">
  <div className="container">
    <div className="row">
      <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
        <div className="brow">
          <h2 className="encompass">Transformative inbound support solutions </h2>
          <h3>Drive excellence and boost customer satisfaction</h3>
          <p>Our unparalleled range of comprehensive inbound support focuses on creating innovative and scalable strategies to improve customer satisfaction and boost your business revenue. </p>
        </div>
      </div>
    </div>
  </div>
  <img src={bootomarrow} className="bootomarrow" alt="" />
</section>
<section className="ib-serivces">
  <div className="container">
    <div className="row">
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
        <div className="led">
        <img src={inbound} className="inbo" alt="" />
          <h4>Inbound tech support </h4>
          <p className="appropriate">From basic tech support to product installation and troubleshooting assistance, we offer expert technical support services. </p>
       
        </div>
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
        <div className="led">
        <img src={order} className="inbo" alt="" />
          <h4>Order taking</h4>
          <p className="appropriate">We handle all your order management needs, reducing bottlenecks, increasing order fulfillment speed and boosting sales. </p>
       
        </div>
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
        <div className="led">
        <img src={userlead} className="inbo" alt="" />
          <h4>Lead qualification</h4>
          <p className="appropriate">
          Our agents leverage cutting-edge tools and techniques to qualify high-quality leads, enhance sales efficiency and boost conversions.
          </p>
         </div>
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
        <div className="led">
        <img src={desksupport} className="inbo" alt="" />
          <h4>24/7 help desk support </h4>
          <p className="appropriate">
          We provide round-the-clock help desk support, swiftly resolving customer queries to improve customer satisfaction. 
          </p>
         </div>
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
        <div className="led">
        <img src={crossselling} className="inbo" alt="" />
          <h4>Cross-selling and up-selling</h4>
          <p className="appropriate">
          We employ advanced analytics to recognize cross-selling and up-selling opportunities, optimizing customer interactions and maximizing revenue. 
          </p>
         </div>
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
        <div className="led">
        <img src={onboarding} className="inbo" alt="" />
          <h4>Appointment taking services</h4>
          <p className="appropriate">
          Through robust scheduling software, we ensure an optimal and organized schedule of appointments to increase your productivity.
          </p>
         </div>
      </div>
    </div>
  </div>
</section>
<hr />
<section className="whyib">
  <div className="container">
    <div className="row text-center" id="Prefer">
      <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
        <h2>Discover the Reasons <br /><span className="lightspan">behind our excellence</span></h2>
      </div>
    </div>
    <div className="row" id="scalability">
    <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
    <div className="newroel">
      {/* <img src={inboundpic2} className="trans" alt="" /> */}
         <img src={logi001} className="trans" alt="" />
    </div>
     </div>
    <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
  <div className="outervoice">
    <div className="voice">
        <div className="rightnew">
        <h4>Smart technology integration</h4>
        <p>We’re equipped with the latest AI-driven IVR systems, noise-cancellation headphones and cloud-based infrastructure to ensure seamless communication. </p>
      </div>
    
    </div>
   <div className="voice">
     <div className="rightnew">
      <h4> Trained and experienced agents</h4>
      </div>
      <p>Our agents are rigorously trained to handle calls effectively and efficiently to increase customer satisfaction and retention. </p>
    
    </div>
    <div className="voice">
     <div className="rightnew">
      <h4> Skill-based routing</h4>
      </div>
      <p>We utilize skill-based routing to direct inquiries to the most qualified agent to improve first-call resolution rate. </p>
    
    </div>
   
    </div>
    </div>
    </div>
  </div>
</section>
<section className="techno">
  <div className="container">
  <div className="row text-center" id="c1">
      <div className="col-xxl-12 col-md-12 col-12">
      <h2 className="technology text-white ">Request a  <span class="green1">callback</span></h2>
      <p className="premier text-white">By partnering with us, you’re choosing a strategic partner dedicated to growing your business. Reach out to us to discover how we can help scale your business.</p>
      <div className="webser">
      
      <form action="Service.php" method="post" enctype="multipart/form-data">
          <ul>
          <li><input type="text" name="name" className="form-control"  placeholder="Name" required /></li>
           <li><input type="email" name="email" className="form-control" placeholder="Email"  required /></li>
           <li><input type="tel" name="phone" className="form-control" placeholder="Phone" required /></li>
            <li> <textarea id="message" title="Message" cols="30"  name='message'  rows="1"  maxlength="500"  placeholder="Enter Message" required /></li>
             <li><input type="submit" className="form-rol" defaultValue="send" /></li>
          </ul>
           </form>
                    </div>
      </div>
    </div>
   

  </div>
</section>
<section className="ibfeatures">
  <div className="container">
    <div className="row text-center">
      <div className="col-xxl-12 col-xl-12 col-md-12 col-12">  
       <h2>Our stand-out features<br /> <span className="lightspan">to ensure exceptional support</span></h2>
        <div className="sfs">
        <Tabs>
       <TabList>
      <Tab>Customized Solutions</Tab>
      <Tab>24*7 Support</Tab>
    </TabList>

    <TabPanel>
      <div className="row" id="tures">
      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12" id="keyto">
      <img src={innre02} className="key1" alt="" />
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12" >
      <div className="Distrution"> 
      <h3>Client-centric approach to provide <span className="lightspan"> personalized experiences</span></h3>
        <div className="leding">
             <h4>Flexible and customized solutions </h4>
          <p className="appropriate">At Logixm, our inbound support services revolve around the unique needs of our amiable clients to foster long-term relationships. </p>
          <p className="appropriate">We tailor our solutions to align with your distinct requirements and goals of our clients to deliver personalized support. </p>
          </div>
          <div className="bnrc">
          <Link
                to="/contact"
                className="btn ">
               Contact Us
              </Link> 
           </div>
      </div>
      </div>
      </div>
    </TabPanel>
    <TabPanel>
    <div className="row" id="tures">
      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">
      <div className="Distrution">
      <h3>24/7 availability to ensure  <span className="lightspan"> uninterrupted assistance</span></h3>
        <div className="leding">
          <h4>Dedicated round the clock support </h4>
          <p className="appropriate">We are available round the clock to assist customers across various time zones and provide them with timely assistance. </p>
          <p className="appropriate">Plus, with our global reach and seamless communication capabilities, we’re equipped to support diverse clientele with ease and effectiveness. </p>
          </div>
          <div className="bnrc">
          <Link
                to="/contact"
                className="btn ">
               Contact Us
              </Link> 
           </div>
      </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12" id="keyto">
      <img src={inboundpic2} className="key1" alt="" />
      </div>
      </div>
    </TabPanel>
  </Tabs>
        </div>
      </div>
    </div>
   
  </div>
</section>
<section className="stsret">
  <div className="container">
    <div className="row text-center">
      <div className="col-xxl-12"> 
        <h2>Frequently asked<span class="lightspan"> questions  </span></h2>
        <p className="Get">Find answers to the most common questions about our inbound support services. If you have a question not answered here, please feel free to  <a className="hightlightedtext" href="/contact">contact us.</a></p>

      </div>
      <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>In what ways can outsourcing my inbound support services to Logixm help me save valuable time and resources for my business?</Accordion.Header>
        <Accordion.Body>
        Outsourcing your inbound support services to Logixm offers your business the advantage of specialized expertise without the overhead costs associated with training and managing an in-house team. This allows you to redirect your internal resources towards your core business activities while our skilled agents handle your customer interactions. 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>What type of reporting will you provide us to gauge the effectiveness of inbound support services?</Accordion.Header>
        <Accordion.Body>
        We provide detailed reporting, including essential metrics like call volume, average handling time, first call resolution rate, customer satisfaction scores, and agent performance. This comprehensive data enables you to closely monitor and optimize the effectiveness of your operations. 

        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>How do your inbound support services differentiate from others? </Accordion.Header>
        <Accordion.Body>
        Our inbound support services stand out by merging cutting-edge technology with personalized customer engagement strategies, along with continuous refinement for better results. This holistic approach ensures exceptional results and outstanding service quality.  

        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>How do you ensure the confidentiality of my data?</Accordion.Header>
        <Accordion.Body>
        We understand the importance of safeguarding business confidential information. We employ stringent compliance measures for the protection of your sensitive information. Moreover, we also sign a non-disclosure agreement with our employees to ensure that your business-sensitive information remains secure and confidential. 

        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>Do you offer 24/7 support to assist businesses across diverse time zones?</Accordion.Header>
        <Accordion.Body>
        Yes. We provide round-the-clock support for your customers across different time zones through our services. 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header>How quickly can you implement your services for my company?</Accordion.Header>
        <Accordion.Body>
        Depending on the scope and requirements of your operations, we aim to have your services operational within a few weeks, guaranteeing a seamless transition with minimal disruptions to your business operations. 
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    </div>
  </div>
</section>
    </Layout>
  );
};

export default InboundSupport;
