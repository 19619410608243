import React from "react";
import bootomarrow from "../img/bootomarrow.png";
import BannerOne from "../components/Banner/BannerOne";
import HeaderOne from "../components/Headers/HeaderOne";
import quote from "../img/quote.png";
import Layout from "../layouts/Layout";
import { Link } from "react-router-dom";
import inbound from "../../src/img/inbound.png";
import outbound from "../../src/img/outbound.png";
import chart from "../../src/img/chart.png";
import lead from "../../src/img/lead.png";
import chat from "../../src/img/chat.png";
import operation from "../../src/img/operation.png";
import girl from "../img/girl.png";
import ctagir from "../img/ctagir.webp";
import gentlman from "../img/gentlman.png";
import gentlman21 from "../img/gentlman21.png";
import orange3 from "../img/orange3.png";
// import inner1pick from "../img/inner1pick.png";

import x1 from "../img/x1.png";
import star from "../img/star.png";
import Accordion from 'react-bootstrap/Accordion';


const Home = () => {
  return (
    <Layout header={1} footer={3} className="" mainClassName="">
<BannerOne />
<section className="banner-bottom">
<div className="container">
<div className="row">
    <div className="col-xxl-12 col-md-12 col-12">
    <h4 className="subheading">WHAT WE OFFER</h4>  
        <h2>Grow your business with our <br /><span className="lightspan">technology-driven solutions</span></h2>
     
    </div>
</div>
</div>
<img src={bootomarrow} className="bootomarrow" alt="" />
</section>
<section className="indus-sec">
    <div className="container">
    <div class="row-outer position-relative w-100 pt-3">
    <div className="row no-gutters m-0">
    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12" id="Unknown">
    <div className="indus-box">
    <img src={inbound} className="inbound1" alt="" />
      <h5>Inbound Support</h5>
      <p>We provide comprehensive inbound support, including addressing customer inquiries, order processing, technical support and more.  </p>
    </div>
  </div>
 
  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12" id="Unknown">
    <div className="indus-box">
    <img src={outbound} className="inbound1" alt="" />
      <h5>Outbound Support</h5>
      <p>Our outbound support solutions are backed by the latest technology and industry best practices to help boost your sales and increase productivity. </p>
    </div>
  </div>
  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12" id="Unknown">
    <div className="indus-box">
    <img src={chat} className="inbound1" alt="" />
       <h5>Chat & Email Support</h5>
       <p>With our dynamic email and chat support services that adhere to the highest data protection standards, we elevate customer satisfaction. 
</p>
    </div>
  </div>
  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12" id="Unknown">
  <div className="indus-box">
  <img src={operation} className="inbound1" alt="" />
    <h5>Operations Management</h5>
    <p>
    We take a holistic approach by harnessing cutting-edge tools to streamline your business processes and drive efficiency. 
    </p>
  </div>
</div>
    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12" id="Unknown">
  <div className="indus-box">
  <img src={lead} className="inbound1" alt="" />
    <h5>Lead Generation</h5>
    <p>
    With our strategic approach, we identify high-quality leads and engage with them to boost your conversions. 
    </p>
  </div>
</div>
    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12" id="Unknown">
  <div className="indus-box">
  <img src={chart} className="inbound1" alt="" />
    <h5>Sales</h5>
    <p>
    We proactively reach out to potential customers and leverage targeted strategies to maximize your sales and revenue growth. 

    </p>
  </div>
</div>

</div>
</div>
    </div>
</section>
<section className="homecta">
  <div className="container">
    <div className="row" id="cta1">
      <div className="col-xxl-7 col-md-7 col-12">
      <div className="meaningful">
   <h2>Ready to start your journey towards <span className="lightspan">unprecedented business success?</span></h2>
   {/* <p className="custr">Lorem Ipsum Has Been The Industry's Standard Dummy Text Ever Since The 1500s, When An Unknown Printer Took A Galley Of Type And</p> */}
   <ul className="when">
     <li><span> <img src={star} className="star" alt="" /></span>Enhance customer satisfaction </li>
     <li><span> <img src={star} className="star" alt="" /></span>Grow your sales </li>
      <li><span> <img src={star} className="star" alt="" /></span>Maximize ROI</li>
        </ul>
        <div className="Getd">
        <a href="/contact" >
          <span className="dd"><img src={quote} alt="" /></span>Get a quote <span></span>
          </a>
        </div>
 
      </div>
      </div>
      <div className="col-xxl-5 col-md-5 col-12">
      <img src={ctagir} className="ctagir" alt="" />
          </div>
      </div>
    </div>

</section>
<section className="signlegirl">
<img src={girl} className="girl" alt="" />
</section>
<section className="techno">
  <div className="container">
  <div className="row text-center" id="c1">
      <div className="col-xxl-12 col-md-12 col-12">
      <h2 className="technology text-white ">Request a  <span class="green1">callback </span></h2>
      <p className="premier text-white">By partnering with us, you’re choosing a strategic partner dedicated to growing your business. Reach out to us to discover how we can help scale your business. </p>
      <div className="webser">
      <form action="Service.php" method="post" enctype="multipart/form-data">
          <ul>
          <li><input type="text" name="name" className="form-control"  placeholder="Name" required /></li>
           <li><input type="email" name="email" className="form-control" placeholder="Email"  required /></li>
           <li><input type="tel" name="phone" className="form-control" placeholder="Phone" required /></li>
            <li> <textarea id="message" title="Message" cols="30"  name='message'  rows="1"  maxlength="500"  placeholder="Enter Message" required /></li>
             <li><input type="submit" className="form-rol" defaultValue="send" /></li>
          </ul>
           </form>
                    </div>
      </div>
    </div>
   

  </div>
</section>
<section className="oufrea">
  <div className="container">
    <div className="row" id="containing">
      <div className="col-xxl-8">
      <div className="who">
      <h4 className="subheading">Our benefits </h4>
        <h2>The Logixm Advantage </h2>
        <p>We collaborate with our clients to provide them with the expertise, skills and infrastructure to excel in their business operations. Through data-backed strategies and innovative platforms, we help our clients streamline their business operations, improve productivity and save costs. </p>
      </div>
      </div>
      <div className="col-xxl-4">
      <div className="bnrcta">
      <a href="/contactus"> <span className="dd"><img src={quote} alt="" /></span>Contact Us </a>
              </div>
      </div>
   
    </div>
     <div className="row" id="Emphires">
     <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
    <div className="Emphires">
    <img src={gentlman} className="x1" alt="" />
    <h4 className="Chooseing">State-of-art infrastructure </h4>
    <div class="grey-line"></div>
    <div class="green-underline"></div>
    <p className="caring">Our robust, adaptable and secure technology ensures smooth customer interaction and the capability to effortlessly manage high volumes during peak periods.</p>
    </div>
    </div>
 
    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
    <div className="Emphires">
    <img src={gentlman21} className="x1" alt="" />
    <h4 className="Chooseing">Strict compliance measures </h4>
    <div class="grey-line"></div>
    <div class="green-underline"></div>
    <p className="caring">We uphold strict compliance with regulations and employ top-tier encryption to safeguard data integrity and customer confidentiality. </p>
    </div>
    </div>
    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
    <div className="Emphires">
    <img src={orange3} className="x1" alt="" />
    <h4 className="Chooseing">Round-the-clock support </h4>
    <div class="grey-line"></div>
    <div class="green-underline"></div>
    <p className="caring ">Our agents are trained to provide 24/7 assistance for customer inquiries and support, helping build trust and loyalty. </p>
    </div>
    </div>
  </div>
  </div>
</section>

{/* <section className="propsition">
<div className="container">
  <div className="row text-center">
    <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
    <h2>The Optimal <span className="green1">BPO Approach </span>for <br />Lead Generation by Hit Rate Solutions</h2>
    </div>
  </div>
  <div className="row" id="assisting">
    <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
    <img src={gentlman} className="gentlman" alt="" />
    </div>
    <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
    <div className="appoint">
    <h4>Appointment Setting Services</h4>
    <p>
As a premier appointment-setting firm, we specialize in assisting insurance agents, real estate agencies, and businesses in both B2B and B2C sectors to streamline their scheduling processes.</p>
    </div>
    <div className="appoint">
    <h4>Cold Calling Services</h4>
    <p>
As a premier appointment-setting firm, we specialize in assisting insurance agents, real estate agencies, and businesses in both B2B and B2C sectors to streamline their scheduling processes.</p>
    </div>
    <div className="appoint">
    <h4>Lead Generation Call Center Services</h4>
    <p>
As a premier appointment-setting firm, we specialize in assisting insurance agents, real estate agencies, and businesses in both B2B and B2C sectors to streamline their scheduling processes.</p>
    </div>
    </div>
  </div>
</div>

</section> */}
<section className="stsret">
  <div className="container">
    <div className="row text-center">
      <div className="col-xxl-12"> 
        <h2>Frequently asked <span class="lightspan"> questions </span> </h2>
        <p className="Get">Find answers to the most common questions about our services. If you have a question not answered here, please feel free to <a className="hightlightedtext" href="/contact">contact us</a></p>

      </div>
      <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>What is your approach to training and quality assurance?</Accordion.Header>
        <Accordion.Body>
        Our agents undergo rigorous training on your products and services manual. They don’t engage with customers until they have achieved the desired level of competency in handling calls. 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Why should I outsource my contact center operations to Logixm?</Accordion.Header>
        <Accordion.Body>
        By outsourcing your contact center operations to us, you’ll gain a myriad of advantages:
        <ul className="faqlist">
          <li>Significant cost savings </li>
          <li>Improved profits </li>
          <li>Access to skilled and experienced resources</li>
          <li>Reduced administrative burden</li>
        </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Do you provide after-hours support?</Accordion.Header>
        <Accordion.Body>
        We offer around-the-clock support to ensure your customers receive timely assistance irrespective of time zones. 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>How do you maintain data confidentiality?</Accordion.Header>
        <Accordion.Body>
        We implement stringent data security measures to safeguard your data and ensure its confidentiality. Furthermore, we also sign a non-confidential agreement with our agents so that your confidential information is not shared with anyone. 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>What reporting do you provide to track and measure the performance of the services?</Accordion.Header>
        <Accordion.Body>
        We offer comprehensive analytics and performance reports that include key metrics such as call volume, resolution times, customer satisfaction scores and service level adherence.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header>What makes your offshoring services stand out from competitors?</Accordion.Header>
        <Accordion.Body>
        We stand out by integrating advanced technologies and robust interaction platforms into our services. 
        <ul className="faqlist">
          <li>Our state-of-art contact center accommodates 100+ workstations. </li>
          <li>We have a dedicated manager to oversee day-to-day operations.</li>
          <li>Our high-speed leased line and powerline backup ensure uninterrupted connectivity.</li>
          <li>Our cutting-edge technology, including noise-cancellation headphones, improves communication quality. </li>
        </ul>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    </div>
  </div>
</section>
{/* <section className="lasthome">
  
</section> */}

</Layout>
  );
};

export default Home;
